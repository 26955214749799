<script lang="ts" setup>
import { dayjs } from 'nvd-js-helpers/dayjs'
import UButton from 'nvd-u/components/UButton.vue'
import HistoryIcon from 'nvd-u/icons/History.vue'
import { useAppStore } from 'src/stores/app.store'
import { useCommandsStore } from 'src/stores/commands.store'
import { useVersionsStore } from 'src/stores/versions.store'
import { computed, ref, watch } from 'vue'

const app = useAppStore()
const commands = useCommandsStore()
const versions = useVersionsStore()

const updatedAt = computed(() => app.sitemap?.updated_at)
const lastSavedAt = computed(() => dayjs(updatedAt.value).fromNow())
const saving = ref(false)

function updateSaving() {
    saving.value = true
    setTimeout(() => {
        if (commands.req.loading) updateSaving()
        else saving.value = false
    }, 1000)
}

watch(() => commands.req.loading, loading => {
    loading && updateSaving()
})

function toggle() {
    app.modals.history = !app.modals.history
    if (app.modals.history) versions.hasNewChanges = false
}

</script>

<template>
    <UButton
        v-if="updatedAt"
        class="project-history-btn"
        icon transparent
        v-tooltip="'Last updated: ' + lastSavedAt"
        @click="toggle">
        <HistoryIcon :class="{saving: saving}"/>
        <div class="dot bg-warning" v-if="versions.hasNewChanges"></div>
    </UButton>
</template>

<style lang="scss" scoped>
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
}

.project-history-btn {
    position: relative;

    .saving {
        animation: rotate 1000ms;
    }

    .dot {
        width: 8px;
        height: 8px;
        position: absolute;
        right: 6px;
        top: 6px;
        border-radius: 50%;
    }
}

</style>
