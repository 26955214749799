import { _titleCase } from 'nvd-js-helpers/string-helper'

export class Wireframe {
  id: string
  height: number
  img: HTMLImageElement
  loaded = false

  constructor(id: string, height: number) {
    this.id = id
    this.height = height

    this.img = new Image()
    this.img.onload = () => this.loaded = true
    this.img.src = this.src
  }

  get src() {
    return `/wireframes/${this.id}.svg`
  }

  get label() {
    return _titleCase(this.id)
  }
}
