<script lang="ts" setup>
import { isMacOs } from 'nvd-js-helpers/osHelper'
import UButton from 'nvd-u/components/UButton.vue'
import UModal from 'nvd-u/components/UModal.vue'
import USwitch from 'nvd-u/components/USwitch.vue'
import TemplateIcon from 'nvd-u/icons/CloudUploadOutline.vue'
import CogOutlineIcon from 'nvd-u/icons/CogOutline.vue'
import LockDragIcon from 'nvd-u/icons/CursorMoveLock.vue'
import SidebarIcon from 'nvd-u/icons/PageLayoutSidebarRight.vue'
import RectangleIcon from 'nvd-u/icons/Rectangle.vue'
import SitemapIcon from 'nvd-u/icons/Sitemap.vue'
import DarkModeSettings from 'src/components/layout/header/project-settings/DarkModeSettings.vue'
import MinimapIcon from 'src/components/layout/header/project-settings/MinimapIcon.vue'
import PageColorDisplayToggle from 'src/components/layout/header/project-settings/PageColorDisplayToggle.vue'
import SettingsItem from 'src/components/layout/header/project-settings/SettingsItem.vue'
import SubmitAsTemplateBtn from 'src/components/layout/header/SubmitAsTemplateBtn.vue'
import { useAuthStore } from 'src/stores/auth.store'
import { useSettingsStore } from 'src/stores/settings.store'

const settings = useSettingsStore()
const auth = useAuthStore()

</script>

<template>
    <UButton icon transparent v-tooltip="'Settings'" @click="settings.showModal = true">
        <CogOutlineIcon/>
    </UButton>
    <UModal
        title="Settings"
        cancel-only
        cancel-title="Close"
        v-model="settings.showModal">
        <DarkModeSettings/>
        <SettingsItem>
            <LockDragIcon/>
            <div class="flex-grow-1">
                Lock dragging
                <div class="text-extra-small mt-2">If ON, pages and blocks can not be dragged</div>
            </div>
            <USwitch v-model="settings.lockDragging"/>
        </SettingsItem>
        <SettingsItem>
            <MinimapIcon/>
            <div class="flex-grow-1">
                Always hide minimap
                <div class="text-extra-small mt-2">If OFF, minimap will appear and disappear automatically</div>
            </div>
            <USwitch v-model="settings.hideMinimap"/>
        </SettingsItem>
        <SettingsItem>
            <SitemapIcon/>
            <div class="flex-grow-1">
                Force Horizontal Layout
                <div class="text-extra-small mt-2">Show nested pages in horizontal layout when Horizontal View is On
                </div>
            </div>
            <USwitch
                :model-value="settings.forceHorizontalView"
                @update:modelValue="settings.setForceHorizontalView"
            />
        </SettingsItem>
        <SettingsItem>
            <SidebarIcon/>
            <div class="flex-grow-1">
                Hide sidebar
                <div class="text-extra-small mt-2">
                    Use <code>{{ isMacOs() ? '⌥' : 'alt' }} + s</code> to quickly toggle between ON and OFF
                </div>
            </div>
            <USwitch v-model="settings.hideSidebar"/>
        </SettingsItem>
        <SettingsItem v-if="auth.isLoggedIn">
            <TemplateIcon/>
            <div class="flex-grow-1">
                Publish as Template
                <div class="text-extra-small mt-2">
                    Submit current project for review
                </div>
            </div>
            <SubmitAsTemplateBtn/>
        </SettingsItem>
        <SettingsItem>
            <RectangleIcon/>
            <div class="flex-grow-1">
                Page color display
                <div class="text-extra-small mt-2">
                    Applies to Planning View only
                </div>
            </div>
            <PageColorDisplayToggle/>
        </SettingsItem>
    </UModal>
</template>

<style scoped lang="scss">
</style>
