import { FetchRequest } from '@/helpers/fetch-request'
import MagicIcon from 'nvd-u/icons/Creation.vue'
import PlusCircleMultipleOutline from 'nvd-u/icons/PlusCircleMultipleOutline.vue'
import PlusIcon from 'nvd-u/icons/PlusThick.vue'
import SitemapIcon from 'nvd-u/icons/Sitemap.vue'
import WebSync from 'nvd-u/icons/Web.vue'
import { defineStore } from 'pinia'
import type { Sitemap } from 'src/classes/Sitemap'
import { crawlResetEvent } from 'src/interfaces/CrawlUpdateEvent'
import { useAuthStore } from 'src/stores/auth.store'
import { useUpgradeStore } from 'src/stores/upgrade.store'
import { markRaw } from 'vue'

const form = { name: '' }
const importForm = { website: '', pages: 500, excluded: [''], metaData: false }
const aiForm = { prompt: '' }

export const useSitemapsStore = defineStore('sitemaps', {
  state: () => ({
    form: { ...form },
    importForm: { ...importForm },
    aiForm: { ...aiForm },
    crawlEvent: { ...crawlResetEvent },
    req: new FetchRequest('sitemaps', 'GET').withProps({ delay: 500 }),
    createReq: new FetchRequest('sitemaps', 'POST'),
    cloneReq: new FetchRequest('', 'POST'),
    archiveReq: new FetchRequest('', 'POST'),
    importReq: new FetchRequest('sitemaps/import', 'POST'),
    genWithAiReq: new FetchRequest('sitemaps/ai-generate', 'POST'),
    templatesReq: new FetchRequest('sitemap-templates', 'GET'),
    submitTemplateReq: new FetchRequest('submit-template', 'POST'),
    createForm: {
      choices: [
        { id: 'scratch', label: 'Start from Scratch', icon: markRaw(PlusIcon) },
        { id: 'ai', label: 'Generate with AI', icon: markRaw(MagicIcon) },
        { id: 'crawl', label: 'Generate by Crawling a Website', icon: markRaw(WebSync) },
        { id: 'xml', label: 'Import from a sitemap.xml file', icon: markRaw(SitemapIcon) },
        { id: 'template', label: 'Start from a Template', icon: markRaw(PlusCircleMultipleOutline) },
      ],
      choice: null as any,
    },
  }),
  getters: {
    ownProjectsCount(): number {
      let auth = useAuthStore()
      return this.req.data.filter(s => s.owner_id === auth.user.id).length
    },
  },
  actions: {
    load() {
      this.req.send().then(r => {
        let us = useUpgradeStore()
        us.checkMaxProjectsExceeded()
      })
    },
    afterCreate(sitemap: any) {
      this.req.data = this.req.data || []
      this.req.data.unshift(sitemap)
      this.resetForm()
      return sitemap
    },
    create(data = null, source = '') {
      let us = useUpgradeStore()
      if (!us.checkMaxProjects()) {
        return Promise.resolve('')
      }

      let body = data || this.form
      body.source = source || this.createForm.choice?.id
      return this.createReq.send({
        body: JSON.stringify(body)
      }).then(this.afterCreate)
    },
    import() {
      let us = useUpgradeStore()
      if (!us.checkMaxProjects()) {
        return Promise.resolve('')
      }
      this.crawlEvent = { ...crawlResetEvent }
      return this.importReq.send({
        body: JSON.stringify(this.importForm)
      }).then(res => {
        this.afterCreate(res)
        if (!res.id) return
        this.crawlEvent.sitemapId = res.id
        return res
      })
    },
    generateWithAI() {
      let us = useUpgradeStore()
      if (!us.checkMaxProjects()) {
        return Promise.resolve('')
      }
      return this.genWithAiReq.send({
        body: JSON.stringify(this.aiForm)
      })
    },
    clone(id: number) {
      let us = useUpgradeStore()
      if (!us.checkMaxProjects()) {
        return Promise.resolve('')
      }
      this.cloneReq.url = `sitemaps/${id}/clone`
      return this.cloneReq.send().then(this.afterCreate)
    },
    archive(sitemap: Partial<Sitemap>) {
      this.archiveReq.url = `sitemaps/${sitemap.id}/archive`
      return this.archiveReq.send().then(res => {
        sitemap.archived = !sitemap.archived
      })
    },
    resetForm() {
      this.form = { ...form }
      this.importForm = { ...importForm }
      this.aiForm = { ...aiForm }
    },
  },
})
