import { applyMixins, cssFontSize, cssVar } from 'nvd-js-helpers/misc'
import { canvasHelper } from 'src/classes/canvas/canvas-helper'
import { HasChildPagesMixin } from 'src/classes/HasChildPages.mixin'
import { HasIdMixin } from 'src/classes/HasId.mixin'
import { useContentView } from 'src/stores/app.store'
import { CanvasItem } from './canvas/CanvasItem'
import type { Sitemap } from './Sitemap'
import { SitemapPage } from './SitemapPage'

export class SitemapSection {
  lastIdProp = 'lastSectionId'
  _type = 'section'
  name: string = ''
  description: string = ''
  children: SitemapPage[] = []
  depth: number = 0

  constructor(sitemap: Sitemap, data) {
    this.sitemap = sitemap
    this.setId(data)
    try {
      for (const key in data) {
        if (key === 'children') {
          for (const page of data.children) {
            let p = new SitemapPage(this.sitemap, page, this)
            this.children.push(p)
          }
        } else {
          this[key] = data[key]
        }
      }

      let br = cssFontSize() * 0.5
      this.ci = new CanvasItem(this.sitemap.canvas, {
        text: this.name,
        textCenter: true,
        editable: true,
        selectable: true,
        hoverable: true,
        borderRadius: [br, br, br, br],
        meta: this,
      })
    } catch (e) {
      console.error('Malformed section data.', e, data)
    }
  }

  update() {
    const ci = this.ci
    const canvas = this.sitemap.canvas
    const fontSize = cssFontSize()
    ci.fontSize = fontSize
    const paddingX = fontSize
    const paddingY = fontSize * 0.5
    const width = fontSize * 14
    const contentView = useContentView()

    ci.text = this.name?.toUpperCase() || ''
    ci.width = width
    ci.fillColor = cssVar('--body-bg')
    ci.textColor = cssVar('--muted')
    ci.height = paddingY * 2 + ci.textHeight
    ci.left = Math.ceil(canvas.width / 2 - width / 2)
    if (!canvas.draggedItem) ci.top = Math.round(canvas.maxY) + fontSize * 5
    ci.paddingX = paddingX
    ci.paddingY = paddingY
    ci.selectable = !contentView.value

    if (this.children) this.children.forEach(p => p.update())

    return this
  }

  drawLine() {
    const ci = this.ci
    const canvas = this.sitemap.canvas
    const ctx = canvas.ctx
    const y = ci.top + ci.height / 2 - 0.5
    const color = cssVar('--border-color')
    canvasHelper.line(ctx, Math.min(canvas.minX, 0), y, Math.max(canvas.maxX, canvas.width), y, color, 1)
  }

  draw() {
    this.drawLine()
    this.ci.draw()

    this.children.forEach(p => p.draw())
  }

  toData() {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      children: this.children.map(ch => ch.toData()),
    }
  }

  get index() {
    const idx = this.sitemap.sections.indexOf(this)
    if (!idx && idx !== 0) return -1
    return idx
  }

  get previousSection() {
    return this.index > 0 ? this.sitemap.sections[this.index - 1] : null
  }
}

applyMixins(SitemapSection, [HasChildPagesMixin, HasIdMixin])

export interface SitemapSection extends HasChildPagesMixin {
}

export interface SitemapSection extends HasIdMixin {
}
