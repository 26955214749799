import { _sleep } from 'nvd-js-helpers/misc'
import { useConfirm } from 'nvd-u/composables/Confirm'
import { useNotify } from 'nvd-u/composables/Notifiy'
import { defineStore } from 'pinia'
import { FetchRequest } from 'src/helpers/fetch-request'
import { sendEmailToAdmin } from 'src/helpers/misc'
import type { PaymentMethod, Plan } from 'src/interfaces/Plan.interface'
import { useAuthStore } from 'src/stores/auth.store'

export const MONTHLY = 'Billed monthly'
export const ANNUALLY = 'Billed annually'
export const PMT_EXISTING = 'Stored payment methods'
export const PMT_NEW = 'Add a new payment method'

export const useBillingStore = defineStore('billing', {
  state: () => ({
    stripe: null,
    cardElement: null,
    choices: [MONTHLY, ANNUALLY],
    choice: ANNUALLY,
    plansReq: new FetchRequest('plans', 'GET'),
    subStatusReq: new FetchRequest('plans/sub-status', 'GET'),
    selectedPlan: null as Plan,
    stripeConfigReq: new FetchRequest('stripe/config'),
    invoicesReq: new FetchRequest('plans/invoices').withProps({
      pagination: true,
      paginationMode: 'append',
    }),
    subscribeReq: new FetchRequest('plans/subscribe', 'POST'),
    confirmPaymentReq: new FetchRequest('/plans/confirm-payment', 'POST'),
    paymentMethodsReq: new FetchRequest('/payment-methods', 'GET'),
    deletePmReq: new FetchRequest('/payment-methods/remove', 'DELETE'),
    defaultPmReq: new FetchRequest('/payment-methods/set-default', 'POST'),
    cancelSubFeedback: {
      showPopup: false,
      req: new FetchRequest('/cancel-sub-feedback', 'POST'),
    },
    state: {
      creatingPM: false,
      pmToggle: PMT_NEW,
      pm: null as PaymentMethod,
    },
  }),
  getters: {
    stripePubKey(): string {
      return this.stripeConfigReq.data.pubKey
    },
    interval(): string {
      return this.choice === MONTHLY ? 'month' : 'year'
    },
    plans(): Plan[] {
      return this.plansReq.data.filter(p => p.title === 'Free' || p.interval === this.interval)
    },
    currentPlan(): Plan {
      const auth = useAuthStore()
      return this.plansReq.data.find(p => p.title === auth.user?.plan?.title)
    },
    hasFreeAccount() {
      const auth = useAuthStore()
      return auth.user?.plan?.title === 'Free'
    },
    hasActiveSub() {
      const auth = useAuthStore()
      return !this.hasFreeAccount && auth.user?.plan_status === 'Active'
    },
    hasInActiveSub() {
      const auth = useAuthStore()
      return !this.hasFreeAccount && auth.user?.plan_status !== 'Active'
    },
    processing(): boolean {
      return this.state.creatingPM || this.confirmPaymentReq.loading || this.subscribeReq.loading
    },
    actionTitle: (store: any) => (plan: Plan) => {
      if (plan === store.currentPlan) return 'Current Plan'

      if (!plan || !store.currentPlan) return 'Upgrade'

      if (plan.title === 'Free') return 'Downgrade'

      if (store.currentPlan.title.includes('Pro Annual') && plan.title === 'Pro Monthly') {
        return 'Downgrade'
      }

      if (store.currentPlan.title.includes('Team')) {
        if ((store.currentPlan.interval === 'month' && plan.interval === 'year') || !store.hasActiveSub) return 'Upgrade'
        return 'Downgrade'
      }

      return 'Upgrade'
    },
  },
  actions: {
    async createPaymentMethod(billTo) {
      this.state.creatingPM = true
      let pm = await this.stripe.createPaymentMethod({
        type: 'card',
        card: this.cardElement,
        billing_details: {
          name: billTo,
        },
      })
      this.state.creatingPM = false
      return pm
    },
    async confirmPayment(clientSecret, pmId, subId, planId) {
      this.state.creatingPM = true
      let pm = await this.stripe.confirmPayment({
        clientSecret,
        confirmParams: {
          payment_method: pmId,
        },
        redirect: 'if_required',
      })
      this.state.creatingPM = false
      return this.confirmPaymentReq.send({
        body: JSON.stringify({ subId, planId })
      }).then(this.afterConfirm)
    },
    afterConfirm(res: any | { message: string, next_bill_date: string, plan: Plan }) {
      const auth = useAuthStore()
      useNotify().success('Success', res.message)
      auth.user.plan = res.plan
      auth.user.plan_status = res.plan_status
      auth.user.next_bill_date = res.next_bill_date
      this.selectedPlan = null
      return this.subStatusReq.send()
    },
    sendSubReq(plan: Plan, pmId) {
      return this.subscribeReq.send({
        body: JSON.stringify({
          pmId,
          id: plan.id
        })
      }).then((res: any) => {
        if (res.needs_confirmation) {
          return this.confirmPayment(res.clientSecret, pmId, res.subId, plan.id)
        }
        return this.afterConfirm(res)
      })
    },
    async changePlan(plan: Plan, billTo) {
      const auth = useAuthStore()

      // check suspicious activity
      if (auth.user?.activities_count < 2 && !auth.user?.email_verified_at && !auth.user?.source) {
        this.state.creatingPM = true
        await _sleep(3000)
        await sendEmailToAdmin('Payment attempt failed', auth.user)
        return useConfirm('Oops something went wrong', `<p>We regret to inform you that we were unable to verify your identity, and as a result, your payment attempt cannot be processed at this time.</p><p>If you believe this to be an error, please reach out to our support team through the chat button. Rest assured, our dedicated team will promptly investigate the matter and provide assistance.</p><p>We appreciate your understanding and cooperation in resolving this matter efficiently.</p>`, {
          okOnly: true,
          okTitle: 'Ok'
        })
      }

      // resume sub
      if (plan === this.currentPlan) {
        return this.sendSubReq(plan, '')
      }

      // cancel sub
      if (plan.title === 'Free') {
        this.cancelSubFeedback.showPopup = true
        return
      }

      // change sub
      if (this.state.pmToggle === PMT_EXISTING) {
        return this.sendSubReq(plan, this.state.pm.id)
      }

      return this.createPaymentMethod(billTo || auth.user.name).then(pm => {
        if (!pm?.paymentMethod?.id) {
          useNotify().error('Can not upgrade', pm?.error?.message || 'Please enter your card details')
          return
        }
        this.sendSubReq(plan, pm.paymentMethod.id)
      })
    },
    resumeSub() {
      let auth = useAuthStore()
      if (auth.user.plan_status === 'Cancelled') return this.changePlan(this.currentPlan, '')
      else this.selectedPlan = this.currentPlan
    },
    loadInvoices() {
      let lastItem = this.invoicesReq.data.data?.at(-1)
      let params = { startingAfter: '' }
      if (lastItem) {
        params.startingAfter = lastItem.id
      }
      this.invoicesReq.params = params
      return this.invoicesReq.send()
    },
  },
})
