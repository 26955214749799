<script lang="ts" setup>
import UButton from 'nvd-u/components/UButton.vue'
import Icon from 'nvd-u/icons/ShapeSquarePlus.vue'
import { useAppStore } from 'src/stores/app.store'

const app = useAppStore()

</script>

<template>
    <UButton
        class="add-new-section-btn"
        icon transparent
        v-tooltip="'Add section at the bottom'"
        @click="app.addSection(true)">
        <Icon style="transform: rotate(90deg)"/>
    </UButton>
</template>

<style scoped lang="scss">
</style>
