import { FileHelper } from 'nvd-js-helpers/FileHelper'
import { _sleep } from 'nvd-js-helpers/misc.js'
import type { ApmCanvas } from 'src/classes/canvas/ApmCanvas'
import type { Sitemap } from 'src/classes/Sitemap'

export class CanvasDownloader {
  sitemap: Sitemap
  maxWidth: number
  maxHeight: number

  constructor(sitemap: Sitemap) {
    this.sitemap = sitemap
  }

  async captureImage({ name = 'generated', bg = '#fff' }): Promise<string[]> {
    let canvas: ApmCanvas = this.sitemap.canvas
    let element = canvas.element
    const padding = 50
    let widthO = canvas.width
    let heightO = canvas.height
    let maxWidth = canvas.maxX - canvas.minX
    let maxHeight = canvas.maxY - canvas.minY

    const maxSize = 2000
    let numChunks = Math.ceil(Math.max(maxHeight, maxWidth) / maxSize)
    let isPortrait = maxHeight > maxWidth
    let w = isPortrait || numChunks === 1 ? maxWidth : maxSize
    let h = isPortrait && numChunks > 1 ? maxSize : maxHeight
    const minX = canvas.minX
    const minY = canvas.minY
    canvas.updateCanvasSize(w, h)
    await _sleep(500)

    const images = []
    for (let i = 0; i < numChunks; i++) {
      let ox = isPortrait ? -minX : -minX - (i * maxSize)
      let oy = isPortrait ? -minY - (i * maxSize) : -minY

      canvas.updateOrigin(ox, oy)
      await _sleep(500)

      // create temp canvas
      let tempC = document.createElement('canvas')
      let ctx = tempC.getContext('2d')
      // adjust the width of the temp canvas
      tempC.width = this.maxWidth = w + padding * 2
      tempC.height = this.maxHeight = h + padding * 2
      // draw background
      ctx.fillStyle = bg
      ctx.fillRect(0, 0, tempC.width, tempC.height)

      // draw the canvas img onto the temp canvas
      ctx.drawImage(element, padding, padding, w, h)

      // watermark
      ctx.font = `italic 16px Roboto, "Helvetica Neue", Arial, sans-serif`
      ctx.fillStyle = 'rgba(0, 0, 0, 0.2)'
      const appName = `Generated with ${window.location.hostname}`
      ctx.fillText(appName, 10, tempC.height - 10)

      images.push(tempC.toDataURL('image/png', 1).replace('image/png', 'image/octet-stream'))
    }

    canvas.updateCanvasSize(widthO, heightO)
    canvas.resetZoomAndCenter()

    return images
  }

  downloadImage({ name = 'generated', bg = '#fff' }) {
    return this.captureImage({ name, bg }).then((dataUrls: any) => {
      for (const dataUrl of dataUrls) {
        FileHelper.downloadDataUrl(dataUrl, name + '.png')
      }
    })
  }

  downloadPdf({ name = 'generated', bg = '#fff' }) {
    return this.captureImage({ name, bg }).then(async dataUrls => {
      let height = this.maxHeight
      let width = this.maxWidth
      const jsPdfLimit = 6000
      const heightRatio = jsPdfLimit / height
      const widthRatio = jsPdfLimit / width
      const ratio = Math.min(heightRatio, widthRatio)
      if (ratio < 1) {
        height = Math.round(height * ratio)
        width = Math.round(width * ratio)
      }

      const jsPDF = (await import('jspdf')).jsPDF

      let doc = new jsPDF({
        orientation: width > height ? 'landscape' : 'portrait',
        unit: 'px',
        hotfixes: ['px_scaling'],
        format: [width, height]
      })
      for (let i = 0; i < dataUrls.length; i++) {
        if (i > 0) doc.addPage([width, height], width > height ? 'landscape' : 'portrait')
        doc.addImage(dataUrls[i], 'png', 0, 0, width, height, '', 'FAST')
      }
      doc.save(name + '.pdf')
    })
  }
}
