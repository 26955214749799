import type { Dayjs } from 'dayjs'
import { dayjs } from 'nvd-js-helpers/dayjs'
import { applyMixins } from 'nvd-js-helpers/misc'
import { useNotify } from 'nvd-u/composables/Notifiy'
import { HasIdMixin } from 'src/classes/HasId.mixin'
import type { SitemapBlock } from 'src/classes/SitemapBlock'
import type { SitemapPage } from 'src/classes/SitemapPage'
import type { SitemapSection } from 'src/classes/SitemapSection'
import type { User } from 'src/interfaces/User.interface'
import { useAppStore } from 'src/stores/app.store'

export class Comment {
  text: string
  dateTime: Dayjs
  parent: SitemapPage | SitemapBlock | SitemapSection
  user: Partial<User>
  lastIdProp = 'lastCommentId'
  readBy: Object = {}
  resolvedBy: Object = {}
  linkedCommentId: number

  toData(): Object {
    return {
      id: this.id,
      text: this.text,
      dateTime: this.dateTime.toISOString(),
      user: this.user,
      readBy: this.readBy,
      resolvedBy: this.resolvedBy,
      linkedCommentId: this.linkedCommentId,
    }
  }

  constructor(parent: SitemapPage | SitemapBlock | SitemapSection, data: Partial<Comment>) {
    this.parent = parent
    this.sitemap = parent.sitemap
    this.setId(data)
    try {
      for (const key in data) {
        if (key === 'dateTime') {
          this.dateTime = dayjs(data.dateTime)
        } else {
          this[key] = data[key]
        }
      }
    } catch (e) {
      console.error('Malformed comment data.', e, data)
    }
  }

  save(activity: 'added' | 'removed' | 'read' | 'edited' | 'resolved' = 'added') {
    let app = useAppStore()
    return app.saveSitemap('tree', 'comment', { comment: { ...this.toData(), link: this.link }, activity })
  }

  remove() {
    // @ts-ignore
    this.parent.comments.splice(this.parent.comments.indexOf(this), 1)
    this.save('removed')
  }

  get link() {
    return window.location + `?p=${this.parent.id}&c=${this.id}`
  }

  copyLink() {
    navigator.clipboard.writeText(this.link)
    useNotify().success('Copied', 'Link copied to clipboard')
  }

  isReadBy(userId) {
    return this.user?.id != userId && !this.readBy[userId]
  }

  hasMentionOf(userId) {
    return this.text?.includes(`data-id="${userId}"`)
  }
}

applyMixins(Comment, [HasIdMixin])

export interface Comment extends HasIdMixin {
}
