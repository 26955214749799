<script lang="ts" setup>
import UDropdown from 'nvd-u/components/UDropdown.vue'
import UMenuItem from 'nvd-u/components/UMenuItem.vue'
import CheckBoldIcon from 'nvd-u/icons/CheckBold.vue'
import MenuDownIcon from 'nvd-u/icons/MenuDown.vue'
import UserAvatar from 'src/components/common/UserAvatar.vue'
import type { SitemapInvite } from 'src/interfaces/SitemapInvite.interface'
import type { User } from 'src/interfaces/User.interface'
import type { MemberRole, MemberType } from 'src/stores/share.store'
import { useShareStore } from 'src/stores/share.store'

const props = defineProps<{
    user: User | SitemapInvite,
    role: MemberRole,
    type: MemberType,
}>()

let share = useShareStore()

let roles = ['Viewer', 'Commenter', 'Editor']

function select(role: MemberRole) {
    share.changeRole(props.user, props.type, role)
}

function removeAccess() {
    share.removeMember(props.user, props.type)
}
</script>

<template>
    <div class="d-flex align-items-center gap-4">
        <UserAvatar style="--avatar-size: 2rem" class="border1" :user="user"/>
        <div class="flex-grow-1">
            <div class="mb-1">{{ user.name || 'Pending...' }}</div>
            <div class="text-small text-muted">{{ user.email }}</div>
        </div>
        <div v-if="role === 'Owner'">{{ role }}</div>

        <UDropdown left v-else>
            <a href="#" class="d-flex align-items-end gap-2">
                {{ role }}
                <MenuDownIcon/>
            </a>

            <template #content>
                <div class="py-2 u-select-options" style="width: 12em">
                    <UMenuItem v-for="r in roles" @click="select(r)">
                        <div class="flex-grow-1">{{ r }}</div>
                        <CheckBoldIcon class="text-primary" v-if="role === r"/>
                    </UMenuItem>
                    <hr class="my-0">
                    <UMenuItem class="text-danger" @click="removeAccess()">
                        Remove access
                    </UMenuItem>
                </div>
            </template>
        </UDropdown>
    </div>
</template>

<style scoped lang="scss">
</style>
