<script setup lang="ts">
import UIconBtn from 'nvd-u/components/UIconBtn.vue'
import RectangleIcon from 'nvd-u/icons/Rectangle.vue'
import RectangleOutlineIcon from 'nvd-u/icons/RectangleOutline.vue'
import { useSettingsStore } from 'src/stores/settings.store'

const settings = useSettingsStore()
</script>

<template>
    <div class="d-flex flex-column align-items-end">
        <UIconBtn :tooltip="settings.colorDisplay" @click="settings.toggleColorDisplay()">
            <RectangleIcon v-if="settings.colorDisplay === 'Fill'"/>
            <RectangleOutlineIcon v-else/>
        </UIconBtn>
        <div class="text-extra-small text-muted text-center w100">{{ settings.colorDisplay }}</div>
    </div>
</template>

