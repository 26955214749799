import { _sleep } from 'nvd-js-helpers/misc'
import { defineStore } from 'pinia'
import {
  emptyRect,
  introArrowHeight,
  introArrowPinWidth,
  introArrowWidth,
  introModalOffset,
} from 'src/components/AppIntro/constants'
import type { IntroStep } from 'src/components/AppIntro/intro-steps'
import { introSteps } from 'src/components/AppIntro/intro-steps'
import { useAppStore } from 'src/stores/app.store'

export const useIntroStore = defineStore('intro', {
  state: () => ({
    steps: introSteps,
    step: introSteps[0],
    lastTarget: { highlightedElements: [] as unknown as NodeListOf<HTMLElement> },
    arrow: { left: 0, top: 0, el: null, transform: '' },
    modal: { left: 1000, top: 20, el: null, width: 500 },
  }),
  getters: {
    isFirstStep(): boolean {
      return this.step.idx === 0
    },
    isLastStep(): boolean {
      return this.step.idx === introSteps.length - 1
    },
  },
  actions: {
    async updatePositions() {
      const targetEl = document.querySelector(this.step.target)
      if (!targetEl) {
        this.next()
        return
      }

      await _sleep(10)

      this.modal.el = document.querySelector('.app-intro-modal')
      const tr = targetEl?.getBoundingClientRect() || emptyRect
      const mr = this.modal.el.getBoundingClientRect()
      let modalL, modalT

      switch (this.step.direction) {
        case 'ul':
          this.arrow.left = tr.left + tr.width / 2 - introArrowPinWidth / 2
          this.arrow.top = tr.bottom
          modalL = this.arrow.left + introArrowWidth - introModalOffset
          modalT = this.arrow.top + introArrowHeight
          break
        case 'ur':
          this.arrow.left = tr.left - introArrowWidth
          this.arrow.top = tr.top + tr.height / 2 - introArrowPinWidth / 2
          modalL = this.arrow.left - mr.width
          modalT = this.arrow.top + introModalOffset
          break
        case 'u':
          this.arrow.left = tr.left + tr.width / 2 - introArrowWidth + introArrowPinWidth / 2
          this.arrow.top = tr.top + tr.height
          modalL = this.arrow.left + mr.width / 2
          modalT = this.arrow.top + introArrowHeight
          break
        case 'dr':
          this.arrow.left = tr.left - introArrowWidth + tr.width / 2 - introArrowPinWidth / 2
          this.arrow.top = tr.top - introArrowHeight
          modalL = this.arrow.left - mr.width + introModalOffset
          modalT = this.arrow.top - mr.height
          break
        case 'dl':
          this.arrow.left = tr.left + tr.width / 2 - introArrowPinWidth / 2
          this.arrow.top = tr.top - introArrowHeight
          modalL = this.arrow.left + introArrowWidth - introModalOffset
          modalT = this.arrow.top - mr.height
          break
        case 'l':
          this.arrow.left = tr.left + tr.width
          this.arrow.top = tr.top + tr.height / 2 - introArrowHeight + introArrowPinWidth / 2
          modalL = this.arrow.left + introArrowWidth
          modalT = this.arrow.top - mr.height
          break
        case 'r':
          this.arrow.left = tr.left - introArrowWidth
          this.arrow.top = tr.top + tr.height / 2 - introArrowHeight + introArrowPinWidth / 2
          modalL = this.arrow.left - mr.width
          modalT = this.arrow.top - mr.height
          break
        case 'center':
          modalL = tr.left + tr.width / 2 - mr.width / 2
          modalT = tr.top + tr.height / 2 - mr.height / 2
          break
      }

      const vw = window.innerWidth
      const vh = window.innerHeight
      let maxL = vw - mr.width - 10
      let maxT = vh - mr.height - 10
      if (modalL > maxL) modalL = maxL
      if (modalL < 0) modalL = 0
      if (modalT > maxT) modalT = maxT
      if (modalT < 0) modalT = 0
      this.modal.left = modalL
      this.modal.top = modalT

      const angles = { ul: 0, ur: 90, u: 180, dr: 180, dl: 0, l: 90, r: 90 }
      const scaleYs = { dl: -1, l: -1, u: -1 }
      const scaleXs = { l: -1, r: -1 }
      this.arrow.transform = `rotate(${angles[this.step.direction]}deg)`

      let scaleY = scaleYs[this.step.direction]
      if (scaleY) this.arrow.transform += ' scaleY(-1)'
      let scaleX = scaleXs[this.step.direction]
      if (scaleX) this.arrow.transform += ' scaleX(-1)'
    },
    highlightTarget() {
      let selector = this.step.target
      if (this.step.highlightedElements) selector += `, ${this.step.highlightedElements}`
      const elements: NodeListOf<HTMLElement> = document.querySelectorAll(selector)

      this.resetHighlighted()

      this.lastTarget.highlightedElements = elements

      for (const element of elements) {
        if (this.step.direction === 'center') return

        element.dataset.zIndex = element.style.zIndex
        element.style.zIndex = '998'
        element.classList.add('d_highlighted')
      }
    },
    resetHighlighted() {
      for (const el of this.lastTarget.highlightedElements) {
        el.classList.remove('d_highlighted')
        el.style.zIndex = el.dataset.zIndex
      }
    },
    update() {
      this.updatePositions()
      this.highlightTarget()
    },
    async selectStep(step: IntroStep, idx) {
      if (this.step.beforeDeSelect) this.step.beforeDeSelect()
      if (step.beforeSelect) step.beforeSelect()
      await _sleep(30)
      step.idx = idx
      this.step = step
    },
    next() {
      if (this.isLastStep) return
      let nextIdx = this.step.idx + 1
      this.selectStep(introSteps[nextIdx], nextIdx)
    },
    previous() {
      if (this.isFirstStep) return
      let nextIdx = this.step.idx - 1
      this.selectStep(introSteps[nextIdx], nextIdx)
    },
    close() {
      const app = useAppStore()
      app.modals.intro = false
    },
    start() {
      const app = useAppStore()
      app.modals.intro = true
      this.selectStep(introSteps[0], 0)
    },
  },
})
