<script lang="ts" setup>
import { dayjs } from 'nvd-js-helpers/dayjs'
import UButton from 'nvd-u/components/UButton.vue'
import { RestoreVersionCommand } from 'src/commands/RestoreVersionCommand'
import { useCommandsStore } from 'src/stores/commands.store'
import { useVersionsStore } from 'src/stores/versions.store'
import { useRoute } from 'vue-router'

const route = useRoute()
let versions = useVersionsStore()
let commands = useCommandsStore()

function apply() {
    new RestoreVersionCommand({ version: versions.selectedVersion }).execute()
    commands.reset()
}
</script>

<template>
    <div class="version-preview d-flex align-items-center gap-2"
         v-if="route.name === 'sitemap' && versions.selectedVersion">
        <div>{{ dayjs(versions.selectedVersion.created_at).format('MMM D, hh:mm a') }}</div>
        <UButton compact @click="apply">Restore this version</UButton>
        <UButton compact secondary @click="versions.closePreview">Close preview</UButton>
    </div>
</template>

<style scoped lang="scss">
</style>
