import { useStorage } from 'nvd-use-storage'
import { defineStore } from 'pinia'
import { useAppStore } from 'src/stores/app.store'
import { ref } from 'vue'

export const useSettingsStore = defineStore('settings', () => {
  const showModal = ref(false)
  const lockDragging = useStorage('set-lock-dragging', true)
  const hideMinimap = useStorage('set-hide-minimap', false)
  const hideSidebar = useStorage('set-hide-sidebar', false)
  const forceHorizontalView = useStorage('force-horizontal-view', false)
  const colorDisplay: 'Outline' | 'Fill' = useStorage('set-color-display', 'Outline')

  function setForceHorizontalView(v: boolean) {
    forceHorizontalView.value = v
    const app = useAppStore()
    app.sitemap.updatePositions()
  }

  function toggleSidebar() {
    hideSidebar.value = !hideSidebar.value
  }

  function toggleColorDisplay() {
    // @ts-ignore
    colorDisplay.value = colorDisplay.value === 'Fill' ? 'Outline' : 'Fill'
  }

  return {
    showModal,
    lockDragging,
    hideMinimap,
    hideSidebar,
    colorDisplay,
    forceHorizontalView,
    setForceHorizontalView,
    toggleSidebar,
    toggleColorDisplay,
  }
})
