import { useAppStore } from 'src/stores/app.store'
import { useUpgradeStore } from 'src/stores/upgrade.store'
import { useCommandsStore } from '../stores/commands.store'

export class Command {
  description: string = 'Parent Command'
  payload: any = null
  commands: any = null
  needRepositioningOfItems = true

  constructor(payload: any) {
    this.payload = payload
    this.commands = useCommandsStore()
  }

  label() {
    return this.description
  }

  run() {
    if (this.needRepositioningOfItems) this.repositionItems()
    this.commands.currentCommandIdx = this.commands.history.indexOf(this)
    this.commands.save()
  }

  repositionItems() {
    const app = useAppStore()
    app.sitemap.updatePositions()
  }

  saveToHistory() {
    this.commands.history.push(this)
  }

  execute() {
    let app = useAppStore()
    if (!app.checkEditableStatus()) return

    if (!this.label().includes('Delete')) {
      let us = useUpgradeStore()
      if (!us.checkMaxPagesExceeded()) return
    }

    this.saveToHistory()
    this.run()
  }

  redo() {
    this.run()
  }

  undo() {
    if (this.needRepositioningOfItems) this.repositionItems()
    this.commands.currentCommandIdx--
    this.commands.saveUndo()
  }
}
