<script lang="ts" setup>
import UButton from 'nvd-u/components/UButton.vue'
import UDropdown from 'nvd-u/components/UDropdown.vue'
import UMenuItem from 'nvd-u/components/UMenuItem.vue'
import HelpCircleOutline from 'nvd-u/icons/HelpCircleOutline.vue'
import KeyboardVariantIcon from 'nvd-u/icons/KeyboardVariant.vue'
import PlayOutline from 'nvd-u/icons/PlayCircleOutline.vue'
import { useAppStore } from 'src/stores/app.store'
import { useIntroStore } from 'src/stores/intro.store'

const app = useAppStore()
const intro = useIntroStore()

</script>

<template>
    <UDropdown left class="help-dd">
        <UButton icon transparent v-tooltip="'Help'">
            <HelpCircleOutline/>
        </UButton>

        <template #content>
            <div class="py-2 content" style="min-width: 14em;">
                <UMenuItem @click="app.modals.keyboardShortcuts = true">
                    <KeyboardVariantIcon/>
                    Keyboard Shortcuts
                </UMenuItem>
                <UMenuItem @click="intro.start()">
                    <PlayOutline/>
                    Onboarding Tutorial
                </UMenuItem>
            </div>
        </template>
    </UDropdown>
</template>

