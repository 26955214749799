<script lang="ts" setup>
import { env } from '@/env'
import UButton from 'nvd-u/components/UButton.vue'
import UserAvatar from 'src/components/common/UserAvatar.vue'
import AddSectionBtn from 'src/components/layout/header/AddSectionBtn.vue'
import HelpBtn from 'src/components/layout/header/HelpBtn.vue'
import HistoryBtn from 'src/components/layout/header/HistoryBtn.vue'
import SearchBtn from 'src/components/layout/header/project-search/SearchBtn.vue'
import ProjectSettingsBtn from 'src/components/layout/header/project-settings/ProjectSettingsBtn.vue'
import VersionPreview from 'src/components/layout/header/VersionPreview.vue'
import { useAppStore } from 'src/stores/app.store'
import { useAuthStore } from 'src/stores/auth.store'
import { useVersionsStore } from 'src/stores/versions.store'
import { RouterLink, useRoute } from 'vue-router'
import AppLogo from '../../common/AppLogo.vue'
import DarkModeToggleBtn from './DarkModeToggleBtn.vue'
import ProfileBtn from './ProfileBtn.vue'
import ProjectDownloadBtn from './ProjectDownloadBtn.vue'
import ProjectShareBtn from './ProjectShareBtn.vue'

const route = useRoute()
let app = useAppStore()
let versions = useVersionsStore()
let auth = useAuthStore()

</script>

<template>
    <header class="main-header d-flex gap-2 align-items-center p-2">
        <RouterLink to="/" class="home-link p-2 rounded" style="background-color: var(--bg)">
            <AppLogo/>
        </RouterLink>

        <h4 class="m-0 text-muted main-heading">
            {{ app.sitemap && route.name === 'sitemap' ? app.sitemap.name : env.appName }}
        </h4>

        <VersionPreview/>

        <div class="flex-grow-1"></div>

        <UButton
            v-if="auth.admin"
            @click="auth.loginAsAdmin()"
            icon transparent secondary
            v-tooltip="`Login back as ${auth.admin.name}`">
            <UserAvatar :user="auth.admin"/>
        </UButton>
        <div class="project-tools d-flex align-items-center gap-2 rounded" style="background-color: var(--bg)">
            <template v-if="route.name === 'sitemap' && app.canvas">
                <SearchBtn/>
                <HistoryBtn v-if="app.sitemap.isEditableByUser()"/>
                <AddSectionBtn v-if="app.sitemap.isEditableByUser()"/>
                <ProjectDownloadBtn/>
                <ProjectShareBtn v-if="auth.isLoggedIn && app.sitemap.isEditableByUser()"/>
                <ProjectSettingsBtn/>
            </template>

            <DarkModeToggleBtn v-else/>

            <HelpBtn v-if="route.name === 'sitemap' && app.canvas"/>
        </div>
        <div class="separator"></div>

        <ProfileBtn/>
    </header>
</template>

<style scoped lang="scss">
.main-header {
    background-color: var(--bg);
    border-bottom: 1px solid var(--border-color);
}

.home-link {
    border-bottom: none;
}

.separator {
    height: 1.5em;
}

</style>

<style lang="scss">
.main-header {
    .u-btn {
        &.d_highlighted {
            background-color: var(--bg) !important;

            &:hover {
                background-color: var(--primary-light) !important;
            }
        }
    }
}

.main-heading {
    input {
        color: var(--muted);
        font-weight: bold;
        padding: 0.25em 0.5em;
    }
}
</style>
