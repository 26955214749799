<script setup lang="ts">
import MenuIcon from 'src/components/layout/sidebar/MenuIcon.vue'
import SidebarLink from 'src/components/layout/sidebar/SidebarLink.vue'
import { useAuthStore } from 'src/stores/auth.store'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()
const auth = useAuthStore()
</script>

<template>
    <SidebarLink path="/billing/subscription">
        <MenuIcon name="subscription"/>
        Subscription
    </SidebarLink>
</template>

<style lang="scss">
</style>
