<script setup lang="ts">
import { gotoRedirectUrl } from 'nvd-js-helpers/misc'
import ULoading from 'nvd-u/components/ULoading.vue'
import { useAuthStore } from 'src/stores/auth.store'
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'

const auth = useAuthStore()
const router = useRouter()

onMounted(async () => {
    google.accounts.id.initialize({
        client_id: '817069591337-cm8fddt2g0smkv6n3rhn5s9pnio5dsna.apps.googleusercontent.com',
        callback: res => {
            auth.googleLoginReq.send({
                body: JSON.stringify({ ...res, source: auth.form.source, referer: auth.form.referer })
            }).then(data => {
                auth.logUserIn(data, 'register')
                auth.modals.login = false
                gotoRedirectUrl(router)
            })
        },
    })

    google.accounts.id.renderButton(document.getElementById('google-sign-in-button'), {
        text: 'continue_with',
        shape: 'rectangular',
        size: 'large',
        theme: 'outline',
    })
})
</script>

<template>
    <div v-if="!auth.googleLoginReq.loading" id="google-sign-in-button" class="all-center"
         style="min-height: 2.5em"></div>
    <ULoading v-else/>
</template>

<style scoped lang="scss">

</style>
