import { useNotify } from 'nvd-u/composables/Notifiy'
import { defineStore } from 'pinia'
import type { SitemapInvite } from 'src/interfaces/SitemapInvite.interface'
import type { User } from 'src/interfaces/User.interface'
import { useAppStore } from 'src/stores/app.store'
import { useUpgradeStore } from 'src/stores/upgrade.store'
import { FetchRequest } from '../helpers/fetch-request'

export type MemberType = 'member' | 'invite'
export type MemberRole = 'Viewer' | 'Commenter' | 'Editor'

export const useShareStore = defineStore('share', {
  state: () => ({
    modals: {
      share: false,
    },
    inviteParams: {
      email: '',
      role: 'Editor' as MemberType,
    },
    inviteUserReq: new FetchRequest('sitemap/share', 'POST'),
    changeRoleReq: new FetchRequest('sitemap/change-role', 'POST'),
    removeMemberReq: new FetchRequest('sitemap/remove-member', 'POST'),
  }),
  actions: {
    invite() {
      let us = useUpgradeStore()
      if (!us.checkMaxPagesExceeded()) return

      const app = useAppStore()
      return this.inviteUserReq.send({
        body: JSON.stringify({ ...this.inviteParams, id: app.sitemap.id })
        // @ts-ignore
      }).then((invite: SitemapInvite) => {
        this.inviteParams.email = ''
        app.sitemap.pending_invites.push(invite)
      })
    },
    changeRole(member: User | SitemapInvite, type: MemberType, newRole: MemberRole) {
      let us = useUpgradeStore()
      if (!us.checkMaxPagesExceeded()) return
      // @ts-ignore
      type === 'member' ? member.pivot.role = newRole : member.role = newRole
      let app = useAppStore()
      return this.changeRoleReq.send({
        body: JSON.stringify({ member, type, newRole, sitemap_id: app.sitemap.id })
      })
    },
    removeMember(member: User | SitemapInvite, type: MemberType) {
      let app = useAppStore()
      return this.removeMemberReq.send({
        body: JSON.stringify({ member, type, sitemap_id: app.sitemap.id })
      }).then(r => {
        let members = type === 'member' ? app.sitemap.members : app.sitemap.pending_invites
        // @ts-ignore
        members.splice(members.indexOf(member), 1)
      })
    },
    async copyLink() {
      await navigator.clipboard.writeText(`${window.location}`)
      useNotify().success('Copied', 'Link copied to clipboard')
    },
  },
})
