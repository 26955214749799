import { useNotify } from 'nvd-u/composables/Notifiy'
import { defineStore } from 'pinia'
import type { User } from 'src/interfaces/User.interface'
import { useAuthStore } from 'src/stores/auth.store'
import { useUpgradeStore } from 'src/stores/upgrade.store'
import { FetchRequest } from '../helpers/fetch-request'

export const useTeamStore = defineStore('team', {
  state: () => ({
    req: new FetchRequest('my-team', 'GET'),
    membershipReq: new FetchRequest('team/membership', 'POST'),
  }),
  actions: {
    load() {
      return this.req.send()
    },
    toggleMembership(user: User) {
      let auth = useAuthStore()

      if (user.id === auth.user.id) return // cant toggle the owner

      let upgrade = useUpgradeStore()
      if (!user.is_member && !upgrade.checkMaxMembers()) return

      this.membershipReq.send({
        body: JSON.stringify({ id: user.id })
      }).then(res => {
        user.is_member = !user.is_member
        useNotify().success('Success', user.is_member ? 'Member added' : 'Member removed')
      })
    },
  },
})
