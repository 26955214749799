import type { CanvasItem } from 'src/classes/canvas/CanvasItem'

export class DraggableMixin {
  ci: CanvasItem = null

  get isBeingDragged() {
    return this.ci.isDraggedItem || (this.ci.canvas.draggedItem && this.ci.isInSelectedItems)
  }

  get isBeingDraggedOver() {
    return this.ci.canvas.draggedItem && !this.isBeingDragged && this.ci.canvas.hoveredItems.has(this.ci)
  }
}
