import { _sleep } from 'nvd-js-helpers/misc'
import { _snakeCase } from 'nvd-js-helpers/string-helper'
import { Url } from 'nvd-js-helpers/url-helper'
import { useConfirm } from 'nvd-u/composables/Confirm'
import { useNotify } from 'nvd-u/composables/Notifiy'
import { useStorage } from 'nvd-use-storage'
import { defineStore } from 'pinia'
import { CanvasDownloader } from 'src/classes/canvas/CanvasDownloader'
import { AddSectionCommand } from 'src/commands/AddSectionCommand'
import { FetchRequest } from 'src/helpers/fetch-request'
import { defaultPage } from 'src/helpers/sitemap-helper'
import { useAuthStore } from 'src/stores/auth.store'
import { useUpgradeStore } from 'src/stores/upgrade.store'
import { useSitemapsStore } from 'src/views/projects/store'
import { computed } from 'vue'
import type { ApmCanvas } from '../classes/canvas/ApmCanvas'
import type { Sitemap } from '../classes/Sitemap'
import { SitemapBlock } from '../classes/SitemapBlock'
import { SitemapPage } from '../classes/SitemapPage'

export function useContentView() {
  const app = useAppStore()
  const sitemapView = useStorage('')
  const simpleView = useStorage(true)
  return computed({
    get() {
      return !!app.canvas?.selectedItem?.meta
        && app.canvas?.selectedItem?.meta?._type === 'page'
        && app.simpleView
        && app.sitemapView === 'Vertical'
    },
    set(opening) {
      if (opening) {
        sitemapView.value = app.sitemapView
        simpleView.value = app.simpleView
      }
      app.canvas.setSelectedItem(opening ? app.sitemap.tree[0].ci : null)
      app.setSimpleView(opening ? true : !!simpleView.value)
      app.setSitemapView(opening ? 'Vertical' : (sitemapView.value || 'Horizontal'))
      opening ? app.canvas.resetZoomAndCenter() : app.canvas.resetZoom(false)
    },
  })
}

export const useAppStore = defineStore('app', {
  state: () => ({
    sitemap: null as Sitemap | null,
    sitemapReq: new FetchRequest('', 'GET'),
    saveSitemapReq: new FetchRequest('', 'PUT'),
    sitemapClosedReq: new FetchRequest('', 'POST'),
    exportActivityReq: new FetchRequest('', 'POST'),
    downloadSitemapReq: new FetchRequest('', 'GET'),
    sitemapView: useStorage('sitemap-view', 'Horizontal' as 'Horizontal' | 'Vertical'),
    simpleView: useStorage('simple-view', true),
    contentModalPage: null as SitemapPage,
    contentModalSearch: '',
    commentsModalPage: null as SitemapPage,
    capturingSitemapImg: false,
    modals: {
      keyboardShortcuts: false,
      history: false,
      intro: false,
    },
  }),
  getters: {
    canvas(): ApmCanvas | null {
      // @ts-ignore
      return this.sitemap?.canvas
    },
    hasDraggedPage() {
      // @ts-ignore
      return this.canvas?.draggedItem?.meta._type === 'page'
    },
    hasHoveredPage() {
      // @ts-ignore
      return this.canvas?.hoveredItem?.meta instanceof SitemapPage
    },
    hasHoveredBlock() {
      // @ts-ignore
      return this.canvas?.hoveredItem?.meta instanceof SitemapBlock
    },
    hasHorizontalView() {
      // @ts-ignore
      return this.sitemapView === 'Horizontal'
    },
    allColors() {
      let colors = []
      this.sitemap.tree.forEach(page => getColors(page))
      this.sitemap.sections.forEach(section => getColors(section))
      return [...new Set(colors)]

      function getColors(item) {
        if (item.color) colors.push(item.color)
        if (item.blocks?.length) item.blocks.forEach(b => getColors(b))
        if (item.children?.length) item.children.forEach(ch => getColors(ch))
      }
    },
    numPages() {
      function getPages(pages: SitemapPage[]) {
        return pages.reduce((total, p) => 1 + getPages(p.children) + total, 0)
      }

      if (!this.sitemap) return 0
      let pages = getPages(this.sitemap.tree)
      this.sitemap.sections.forEach(s => pages += getPages(s.children))
      return pages
    },
    maxPages(): number {
      let auth = useAuthStore()
      if (auth.isOnTrial) return 2000
      if (auth.belongsToATeam) return 10000
      let ownerMax = this.sitemap?.member_access ? +this.sitemap?.owner_plan?.max_pages : 0
      let planMax = auth.user?.plan?.max_pages || 10
      return Math.max(ownerMax, planMax)
    },
  },
  actions: {
    setSitemapView(view: 'Horizontal' | 'Vertical'): void {
      this.sitemapView = view
      this.sitemap.updatePositions()
    },
    setSimpleView(value: boolean): void {
      this.simpleView = value
      this.sitemap.updatePositions()
    },
    editContent(page: SitemapPage) {
      let contentView = useContentView()
      contentView.value = true
      this.canvas.setSelectedItem(page.ci)
    },
    setInitialZoom(initialZoom = 0) {
      if (initialZoom) {
        this.canvas.setZoom(initialZoom)
        return
      }
      let count = this.numPages
      if (count > 30) this.canvas.setZoom(0.75)
      else if (count > 20) this.canvas.setZoom(0.8)
      else if (count > 9) this.canvas.setZoom(0.9)
      else if (this.canvas.isBiggerThanViewPort) this.canvas.fitToScreen()
    },
    async addSection(locateSection: boolean = false) {
      let us = useUpgradeStore()
      if (!us.checkMaxPagesExceeded()) return

      const SitemapSection = (await import('src/classes/SitemapSection')).SitemapSection

      const section = new SitemapSection(this.sitemap, {
        name: 'Section ' + (this.sitemap.sections.length + 1),
        children: [defaultPage()]
      })

      new AddSectionCommand({ section }).execute()

      this.canvas.setEditedItem(section.ci)
      this.canvas.setSelectedItem(section.ci)

      if (locateSection) {
        await _sleep(200)
        await this.canvas.locateItem(section.ci)
      }
    },
    handleSearchParams() {
      let pageId = Url.get('p')
      let commentId = Url.get('c')
      if (commentId && pageId) {
        let page = this.sitemap.findPageById(+pageId)
        if (page) {
          this.commentsModalPage = page
        }
      }
    },
    saveSitemap(prop = '', meta: any = '', extraData = {}) {
      if (meta !== 'comment' && !this.checkEditableStatus()) return
      let auth = useAuthStore()
      if (!this.sitemap?.id || !auth.isLoggedIn || this.sitemap?.is_template || this.sitemap?.isPublicDemo) return
      this.saveSitemapReq.url = `sitemaps/${this.sitemap.id}`

      let data = this.sitemap.toData()
      if (prop) data = { name: prop, value: data[prop], meta, extra: extraData }

      return this.saveSitemapReq.send({
        body: JSON.stringify(data)
      })
    },
    downloadSitemapXml() {
      function hasEmptyLinks(pages: SitemapPage[]) {
        return pages.find(p => !p.link || hasEmptyLinks(p.children))
      }

      let containsEmptyLinks = hasEmptyLinks(this.sitemap.tree)
      if (!containsEmptyLinks) {
        for (const section of this.sitemap.sections) {
          containsEmptyLinks = hasEmptyLinks(section.children)
          if (containsEmptyLinks) break
        }
      }

      if (!containsEmptyLinks) {
        this.downloadSitemap('xml')
        return
      }

      useConfirm(
        'Export Sitemap.xml',
        `<p class="text-warning"><b>Warning!</b></p>
            <p>One or more pages of this sitemap do not have links set. </p>
            <p>These pages will still be included in your sitemap.xml without a link.</p>
            <p>We recommend setting links for these pages before exporting your sitemap.xml file.</p>`,
        {
          okTitle: 'Ignore & Proceed',
          cancelTitle: 'Cancel Export'
        }
      ).then(yes => {
        if (yes) this.downloadSitemap('xml')
      })
    },
    downloadSitemap(format, extension = '') {
      let us = useUpgradeStore()
      if (!us.checkMaxPagesExceeded()) return
      if (!this.sitemap) return
      this.downloadSitemapReq.url = `sitemaps/${this.sitemap.id}/download?format=${format}`
      return this.downloadSitemapReq.download(_snakeCase(this.sitemap.name) + `.${extension || format}`)
    },
    closeSitemap() {
      let auth = useAuthStore()
      if (!this.sitemap?.id || !auth.user?.id) return
      this.sitemapClosedReq.url = `sitemaps/${this.sitemap.id}/closed`
      this.setSitemap(null)
      return this.sitemapClosedReq.send()
    },
    downloadPng() {
      this.capturingSitemapImg = true
      return this.recordExportActivity('png').then(() => {
        if (this.exportActivityReq.data.status === 'fail') return
        return new CanvasDownloader(this.sitemap).downloadImage({
          name: _snakeCase(this.sitemap.name),
          bg: '#fff'
        })
      }).finally(() => this.capturingSitemapImg = false)
    },
    downloadPdf() {
      this.capturingSitemapImg = true
      return this.recordExportActivity('pdf').then(() => {
        if (this.exportActivityReq.data.status === 'fail') return
        return new CanvasDownloader(this.sitemap).downloadPdf({
          name: _snakeCase(this.sitemap.name),
          bg: '#fff'
        })
      }).finally(() => this.capturingSitemapImg = false)
    },
    recordExportActivity(format) {
      if (this.sitemap.isPublicDemo) return Promise.resolve(true)

      let us = useUpgradeStore()
      if (!us.checkMaxPagesExceeded()) return

      this.exportActivityReq.url = `activity/export/${this.sitemap.id}`
      return this.exportActivityReq.send({
        body: JSON.stringify({ format })
      })
    },
    setSitemap(sitemap: Sitemap) {
      this.sitemap = sitemap
    },
    updateSitemapInListing() {
      let sitemaps = useSitemapsStore()
      // @ts-ignore
      if (!this.sitemap || !sitemaps.req.data.data) return
      // @ts-ignore
      let map = sitemaps.req.data.data.find(sm => sm.id === this.sitemap.id)
      if (map) {
        for (const key in this.sitemap) {
          // @ts-ignore
          map[key] = this.sitemap[key]
        }
        map.updated_at = new Date()
      }
    },
    checkEditableStatus() {
      let auth = useAuthStore()
      if (auth.isLoggedIn && !this.sitemap?.isEditableByUser()) {
        useNotify().error('Can not edit', 'To edit this project, please contact its owner')
        return false
      }
      return true
    }
  },
})
