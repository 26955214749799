import type { CanvasItem } from 'src/classes/canvas/CanvasItem'
import type { Sitemap } from 'src/classes/Sitemap'
import { SitemapPage } from 'src/classes/SitemapPage'
import type { SitemapSection } from 'src/classes/SitemapSection'
import { AddPageCommand } from 'src/commands/AddPageCommand'
import { defaultPage, sitemapConfig } from 'src/helpers/sitemap-helper'
import { useAppStore } from 'src/stores/app.store'
import { useSettingsStore } from 'src/stores/settings.store'
import { useUpgradeStore } from 'src/stores/upgrade.store'

export class HasChildPagesMixin {
  parent: SitemapPage | SitemapSection
  sitemap: Sitemap
  children: SitemapPage[] = []
  ci: CanvasItem = null
  collapsed: Boolean = false
  depth: number = 0

  get isRoot(): boolean {
    // @ts-ignore
    return !this.parent
  }

  get belongsToSection() {
    return this.parent?._type === 'section'
  }

  get isOnlyChild() {
    return this.parent?.children?.length === 1
  }

  get forceHorizontal() {
    let app = useAppStore()
    const settings = useSettingsStore()
    return app.hasHorizontalView && (
      this.depth === 0
      || (this.children.length < 2 && this.parent?.forceHorizontal)
      || settings.forceHorizontalView
    )
  }

  get fullWidth(): number {
    const gap = sitemapConfig.page.gap
    const hasChildren = this.children.length && !this.collapsed

    if (!hasChildren) return this.ci.width

    if (this.forceHorizontal) {
      if (this.children.length === 1) return this.ci.width
      return this.children.reduce((w, ch) => w + ch.fullWidth + gap, 0) - gap
    }

    return this.children.reduce((w, ch) => Math.max(ch.fullWidth, w), 0) + sitemapConfig.page.leftGap
  }

  get fullHeight(): number {
    const hasChildren = this.children.length && !this.collapsed

    if (!hasChildren) return this.ci.height

    const gap = sitemapConfig.page.gap
    return this.children.reduce((h, child) => h + child.fullHeight + gap, this.ci.height)
  }

  addChildAt(index: number, data = {}) {
    let upgrade = useUpgradeStore()
    if (!upgrade.checkMaxPages()) return

    // @ts-ignore
    if (this._type === 'page') this.collapsed = false
    // @ts-ignore
    const page = new SitemapPage(this.sitemap, defaultPage(data), this)
    new AddPageCommand({ page, index }).execute()
    this.sitemap.canvas.setEditedItem(this.children[index].ci)
    return page
  }

  addChild(childPageData = {}) {
    let idx = this.forceHorizontal ? Math.ceil(this.children.length / 2) : 0
    return this.addChildAt(idx, childPageData)
  }

  addChildPageAt(idx: number, page: SitemapPage) {
    this.children.splice(idx, 0, page)
    // @ts-ignore
    page.parent = this
  }

  removeChildPage(page: SitemapPage) {
    this.children.splice(this.children.indexOf(page), 1)
  }

  selectChildBelow() {
    if (!this.children.length) return
    const idx = this.forceHorizontal ? Math.floor(this.children.length / 2) : 0
    const app = useAppStore()
    app.canvas.setSelectedItem(this.children[idx].ci)
  }
}
