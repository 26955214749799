import type { Sitemap } from 'src/classes/Sitemap'
import { dayjs } from 'nvd-js-helpers/dayjs'
import { useAppStore } from 'src/stores/app.store'
import type { Version } from 'src/stores/versions.store'
import { useVersionsStore } from 'src/stores/versions.store'
import { Command } from './Command'

interface Payload {
  version: Version,
}

export class RestoreVersionCommand extends Command {
  description = 'Delete item'
  declare payload: Payload
  originalSitemap: Sitemap

  constructor(payload: Payload) {
    super(payload)
    this.payload = payload
  }

  label(): string {
    return `Restored from ${dayjs(this.version.created_at).format('MMM D, YYYY hh:mm a')}`
  }

  get version() {
    return this.payload.version
  }

  run() {
    const versions = useVersionsStore()
    const app = useAppStore()

    // backup original for undo
    this.originalSitemap = versions.originalSitemap
    // close preview
    app.modals.history = false
    versions.selectedVersion = null
    versions.originalSitemap = null

    super.run()
  }

  undo() {
    const app = useAppStore()
    app.setSitemap(this.originalSitemap)
    super.undo()
  }
}
