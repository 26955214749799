import { dayjs } from 'nvd-js-helpers/dayjs'
import { setRedirectUrl } from 'nvd-js-helpers/misc'
import { useStorage } from 'nvd-use-storage'
import { defineStore } from 'pinia'
import type { User } from 'src/interfaces/User.interface'
import { useSitemapsStore } from 'src/views/projects/store'
import { TOKEN_KEY, USER_KEY } from '../constants'
import { FetchRequest } from '../helpers/fetch-request'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    form: {
      name: '',
      email: '',
      password: '',
      otp: '',
      newPassword: '',
      inviteId: '',
      source: useStorage('user_source', ''),
      referer: useStorage('user_referer', ''),
    },
    modals: {
      login: false,
    },
    loginReq: new FetchRequest('login', 'POST'),
    googleLoginReq: new FetchRequest('login-with-google', 'POST'),
    signupReq: new FetchRequest('register', 'POST'),
    logoutReq: new FetchRequest('logout', 'POST'),
    forgotReq: new FetchRequest('forgot-password', 'POST'),
    resetReq: new FetchRequest('reset-password', 'POST'),
    emailVerificationReq: new FetchRequest('send-verification-email', 'POST'),
    refreshUserReq: new FetchRequest('user', 'GET'),
    user: useStorage(USER_KEY) as User,
    admin: useStorage('admin-user') as User,
    adminToken: useStorage('admin-user-token'),
    authToken: useStorage(TOKEN_KEY),
  }),
  getters: {
    isLoggedIn: state => state.user && state.authToken,
    isOnTrial(): boolean {
      return this.user?.email_verified_at
        && this.user?.plan.title === 'Free'
        && (dayjs().diff(dayjs(this.user.email_verified_at), 'hours') < 24 || this.user?.activities_count < 100)
    },
    isOnUnverifiedTrial() {
      return this.user?.plan.title === 'Free'
        && (dayjs().diff(dayjs(this.user.created_at), 'hours') < 24 || this.user?.activities_count < 100)
    },
    belongsToATeam(): boolean {
      return this.user?.memberships?.length > 0 || false
    },
    maxTeamMembers(): number {
      return this.user?.plan?.max_users + this.user?.extra_team_members_count
    },
  },
  actions: {
    loginAsAdmin() {
      if (!this.admin) {
        console.error('No admin user')
        return
      }
      this.user = this.admin
      this.authToken = this.adminToken
      this.admin = null
      this.adminToken = ''
      window.location.reload()
    },
    showLoginModal() {
      this.modals.login = true
    },
    logUserIn(userData: any, source: 'login' | 'register' = 'login') {
      this.authToken = userData.token
      this.user = userData.user

      if (source === 'register' && this.user.source === 'sitemap-finder') {
        setRedirectUrl(`/sitemap-finder`)
      } else if (userData.demo_project_id) {
        setRedirectUrl(`/p/${userData.demo_project_id}?intro=1`)
      }
    },
    register() {
      return this.signupReq.send({
        body: JSON.stringify(this.form)
      }).then((data: any) => {
        this.logUserIn(data, 'register')
      })
    },
    login() {
      return this.loginReq.send({
        body: JSON.stringify(this.form)
      }).then((data: any) => {
        this.logUserIn(data)
      })
    },
    sendForgotReq() {
      return this.forgotReq.send({
        body: JSON.stringify({ email: this.form.email })
      }).then((data: any) => {
        this.logUserIn(data)
      })
    },
    resetPassword() {
      return this.resetReq.send({
        body: JSON.stringify({ email: this.form.email, otp: this.form.otp, password: this.form.newPassword })
      }).then((data: any) => {
        this.logUserIn(data)
      })
    },
    logout() {
      return this.logoutReq.send().then((data: any) => {
        this.authToken = null
        this.user = null
        let sitemaps = useSitemapsStore()
        sitemaps.req.data = []
        window.location.reload()
      })
    },
    refreshUser() {
      return this.refreshUserReq.send().then((data: any) => {
        if (data.id && data.email) this.user = data
      })
    },
  },
})
