<script lang="ts" setup>
import UButton from 'nvd-u/components/UButton.vue'
import { useTheme } from 'src/composables/useTheme'

const theme = useTheme()

</script>

<template>
    <UButton icon transparent @click="theme.toggle()" v-tooltip="theme.tooltip">
        <svg viewBox="0 0 24 24" width="1em"
             fill="currentColor" fill-rule="evenodd" clip-rule="evenodd">
            <path :d="theme.path"></path>
        </svg>
    </UButton>
</template>

<style scoped lang="scss">
</style>
