import type { Sitemap } from 'src/classes/Sitemap'

export class HasIdMixin {
  id: number
  lastIdProp: string
  sitemap: Sitemap

  setId(data) {
    if (!data.id) {
      this.id = this.sitemap[this.lastIdProp] = this.sitemap[this.lastIdProp] + 1
      return
    }

    this.id = data.id
    this.sitemap[this.lastIdProp] = Math.max(this.id, this.sitemap[this.lastIdProp])
  }
}
