export interface CrawlUpdateEvent {
  completed: boolean
  failed: boolean
  message: string
  sitemapId: number
  website: string
  pages: any[]
}

export const crawlResetEvent: CrawlUpdateEvent = {
  completed: false,
  failed: false,
  message: '',
  sitemapId: null,
  website: '',
  pages: [],
}
