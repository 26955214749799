<script setup lang="ts">
import { useIntroStore } from 'src/stores/intro.store'
import { computed, onMounted, ref } from 'vue'

const intro = useIntroStore()
const angles = { ul: 0, ur: 90, dl: 180, dr: 270 }
const el = ref()
const left = computed(() => intro.arrow.left + 'px')
const top = computed(() => intro.arrow.top + 'px')
const transform = computed(() => intro.arrow.transform)

onMounted(() => {
    intro.arrow.el = el.value
})
</script>

<template>
    <!--    <img class="app-intro-arrow" ref="el" src="/images/arrow.png" alt="Arrow">-->
    <svg class="app-intro-arrow" ref="el" width="57" height="56" viewBox="0 0 57 56" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path d="M5 5C5 5 5 30 30 30C55 30 55 55 55 55" stroke="var(--primary)" stroke-width="3"/>
        <path d="M5 0L9.33013 7.5H0.669873L5 0Z" fill="var(--primary)"/>
    </svg>
</template>

<style lang="scss" scoped>
.app-intro-arrow {
    position: absolute;
    left: v-bind(left);
    top: v-bind(top);
    transform: v-bind(transform);
    transform-origin: center center;
    transition: all 0.3s ease-out;
    z-index: 999;
}
</style>
