<script lang="ts" setup>
import { useAuthStore } from '@/stores/auth.store'
import UButton from 'nvd-u/components/UButton.vue'
import PlusIcon from 'nvd-u/icons/Plus.vue'
import BillingMenu from 'src/components/layout/sidebar/BillingMenu.vue'
import MenuIcon from 'src/components/layout/sidebar/MenuIcon.vue'
import SidebarLink from 'src/components/layout/sidebar/SidebarLink.vue'
import { useBillingStore } from 'src/stores/billing.store'
import { useRoute, useRouter } from 'vue-router'

const auth = useAuthStore()
const router = useRouter()
const route = useRoute()
const billing = useBillingStore()
if (!billing.plans?.length) billing.plansReq.send()

</script>

<template>
    <div class="main-sidebar p-4 d-flex flex-column gap-2">
        <UButton outline class="mb-4" @click="router.push('/projects/create')">
            <PlusIcon/>
            New Project
        </UButton>
        <SidebarLink path="/projects">
            <MenuIcon name="projects"/>
            Projects
        </SidebarLink>
        <SidebarLink path="/team" v-if="billing.currentPlan?.title?.includes('Team')">
            <MenuIcon name="team"/>
            Team
        </SidebarLink>
        <SidebarLink path="/profile">
            <MenuIcon name="profile"/>
            Account
        </SidebarLink>
        <BillingMenu/>
        <hr class="w100 my-2">
        <SidebarLink path="/sitemap-finder">
            <MenuIcon name="sitemapFinder"/>
            Sitemap Finder
        </SidebarLink>
    </div>
</template>

<style lang="scss">
.main-sidebar {

}
</style>
