import { applyMixins, cssFontSize, cssVar } from 'nvd-js-helpers/misc'
import { colorHelper } from 'nvd-u/helpers/color-helper'
import { Comment } from 'src/classes/Comment'
import { DraggableMixin } from 'src/classes/Draggable.mixin'
import type { DropSpaceLocation } from 'src/classes/DropSpace'
import { DropSpace } from 'src/classes/DropSpace'
import { HasChildPagesMixin } from 'src/classes/HasChildPages.mixin'
import { HasCommentsMixin } from 'src/classes/HasComments.mixin'
import { HasIdMixin } from 'src/classes/HasId.mixin'
import type { SitemapSection } from 'src/classes/SitemapSection'
import { useAppStore } from 'src/stores/app.store'
import { useSettingsStore } from 'src/stores/settings.store'
import { AddBlockCommand } from '../commands/AddBlockCommand'
import { countPages, defaultBlock, sitemapConfig } from '../helpers/sitemap-helper'
import { CanvasItem } from './canvas/CanvasItem'
import { Connection } from './canvas/Connection'
import type { Sitemap } from './Sitemap'
import { SitemapBlock } from './SitemapBlock'

export class SitemapPage {
  parent: SitemapPage | SitemapSection
  _type = 'page'
  lastIdProp = 'lastPageId'
  name: string = ''
  color: string = '#03a9f4'
  link: string = ''
  titleTag: string = ''
  metaDesc: string = ''
  keywords: string = ''
  h1: string = ''
  h2: string = ''
  content: string = ''
  last_modified: string = ''
  collapsed: Boolean = false
  blocks: SitemapBlock[] = []
  children: SitemapPage[] = []
  header: CanvasItem = null
  dropSpaces = {
    before: null as DropSpace,
    after: null as DropSpace,
    over: null as DropSpace,
  }

  toData(): Object {
    return {
      id: this.id,
      name: this.name,
      color: this.color,
      link: this.link,
      titleTag: this.titleTag,
      metaDesc: this.metaDesc,
      keywords: this.keywords,
      h1: this.h1,
      h2: this.h2,
      content: this.content,
      collapsed: this.collapsed,
      blocks: this.blocks.map(b => b.toData()),
      children: this.children.map(ch => ch.toData()),
      comments: this.comments.map(c => c.toData()),
    }
  }

  // @ts-ignore
  constructor(sitemap: Sitemap, data: Partial<SitemapPage>, parent: SitemapPage | SitemapSection = null) {
    this.sitemap = sitemap
    this.parent = parent
    this.setId(data)
    this.initComments()

    try {
      // @ts-ignore
      const { children, blocks, comments, ...rest } = data

      for (const key in rest) {
        // @ts-ignore
        this[key] = rest[key]
      }

      if (children) {
        children.forEach((child: SitemapPage) => {
          this.children.push(new SitemapPage(this.sitemap, child, this))
        })
      }
      if (blocks) {
        blocks.forEach((block: Object) => {
          this.blocks.push(new SitemapBlock(this, block))
        })
      }

      if (comments) {
        comments.forEach((comment: Object) => {
          this.comments.push(new Comment(this, comment))
        })
      }

      const { width, fontSize, paddingX, paddingY, borderWidth, headerHeight, borderRadius } = this.styles
      this.ci = new CanvasItem(this.sitemap.canvas, {
        left: 0,
        top: 0,
        width,
        fontSize: fontSize,
        paddingX,
        paddingY,
        height: 0,
        borderColor: this.color,
        fillColor: '#fff',
        disableHoverFx: true,
        borderWidth,
        text: this.name,
        textBold: true,
        textColor: this.color,
        hoverable: true,
        hoverOffset: fontSize * 2,
        selectable: true,
        editable: true,
        draggable: !this.isRoot,
        borderRadius: [borderRadius, borderRadius, borderRadius, borderRadius],
        meta: this,
      })

      this.header = new CanvasItem(this.sitemap.canvas, {
        left: 0,
        top: 0,
        height: headerHeight,
        width,
        hoverable: false,
        selectable: false,
        editable: false,
        meta: this,
        fillColor: this.color,
        fontSize: headerHeight / 2,
        paddingY: headerHeight / 4,
        textBold: true,
        paddingX: headerHeight / 2,
        text: '● ● ●',
        textColor: cssVar('--light'),
        borderRadius: [borderRadius, borderRadius, 0, 0],
      })

      this.ci.linkedItems.push(this.header)
    } catch (e) {
      console.error('Malformed page data.', e, data)
    }
  }

  collapseLargePages() {
    this.collapsed = !this.isRoot && !this.isOnlyChild && countPages(this.children) > 20
    this.children.forEach(ch => ch.collapseLargePages())
    this.sitemap.updatePositions()
  }

  get styles() {
    const app = useAppStore()
    const bodyFontSize = cssFontSize()
    const fontSize = sitemapConfig.page.fontSize()
    const paddingX = fontSize * 0.5
    const width = app.simpleView ? bodyFontSize * 8 : bodyFontSize * 9
    let paddingY = sitemapConfig.page.paddingY()
    if (app.simpleView) paddingY += paddingY * 0.75
    const blockHeight = sitemapConfig.block.height()
    const headerHeight = app.simpleView ? 0 : bodyFontSize * 0.5
    const blockGap = sitemapConfig.block.gap
    const height = headerHeight + paddingY + (blockHeight + blockGap) * 2 + blockGap + (this.blocks.filter(b => !b.isBeingDragged).reduce((h, b) => h + b.height + blockGap, 0))
    return {
      width,
      height: app.simpleView ? (fontSize + paddingY * 2) : height,
      gap: sitemapConfig.page.gap,
      fontSize,
      paddingX,
      paddingY: app.simpleView ? paddingY : paddingY + headerHeight,
      blockHeight,
      headerHeight,
      borderWidth: 2,
      blockGap,
      borderRadius: fontSize * 0.5,
    }
  }

  get index() {
    const idx = this.parent?.children?.indexOf(this)
    if (!idx && idx !== 0) return -1
    return idx
  }

  get previousPage() {
    return this.index > 0 ? this.parent?.children[this.index - 1] : null
  }

  get nextPage() {
    return this.parent?.children[this.index + 1]
  }

  updateVertical() {
    const parent = this.parent
    const { gap } = this.styles
    const ci = this.ci
    const previousPage = this.previousPage

    const rootTop = sitemapConfig.root.top
    const rootLeft = sitemapConfig.root.left

    if (this.isRoot) {
      this.header.top = ci.top = rootTop
      this.header.left = ci.left = rootLeft
      return
    }

    const leftGap = sitemapConfig.page.leftGap
    const left = (parent.isRoot ? rootLeft : parent.ci.left) + leftGap
    const top = Math.round(previousPage ? previousPage.ci.top + previousPage.fullHeight : parent.ci.bottom) + gap
    this.header.left = ci.left = Math.round(left)
    this.header.top = ci.top = Math.round(top)
  }

  updateHorizontal() {
    const parent = this.parent
    const canvas = this.sitemap.canvas
    const { width, gap } = this.styles
    const ci = this.ci

    if (this.isRoot) {
      this.header.top = ci.top = sitemapConfig.root.top
      this.header.left = ci.left = Math.round(canvas.width / 2 - width / 2)
      return
    }

    const startLeft = parent.ci.cx - parent.fullWidth / 2
    const previousPage = this.previousPage
    const left = Math.round(previousPage ? previousPage.ci.left + previousPage.fullWidth + gap : startLeft)

    const top = Math.round(parent.ci.bottom + gap)
    this.header.left = ci.left = Math.round(left)
    this.header.top = ci.top = Math.round(top)
  }

  updateDraggedState() {
    const ci = this.ci
    const canvas = ci.canvas

    // reset drag state
    if (!canvas.hasDraggedPage) {
      this.dropSpaces.before = null
      this.dropSpaces.after = null
      this.dropSpaces.over = null
      return
    }

    // don't draw drop spaces for the dragged page
    if (this.isBeingDragged) {
      // add children to selection
      this.addToSelectedItems()
      return
    }

    this.updateDropSpace('over')

    if (!this.previousPage?.isBeingDragged && !this.isRoot && this.index === 0) this.updateDropSpace('before')

    if (!this.nextPage?.isBeingDragged && !this.isRoot) this.updateDropSpace('after')
  }

  addToSelectedItems() {
    const canvas = this.ci.canvas
    canvas.selection.add(this.ci)
    this.children.forEach(ch => ch.addToSelectedItems())
  }

  updateDropSpace(location: DropSpaceLocation) {
    if (this.dropSpaces[location]) this.dropSpaces[location].update()
    else this.dropSpaces[location] = new DropSpace({
      page: this,
      location,
    })
  }

  get isLastPageLeftInSection() {
    return this.belongsToSection && this.isOnlyChild
  }

  update() {
    if (this.blocks) this.blocks.forEach(b => b.update())
    const { height, width, paddingX, paddingY, borderWidth, fontSize, headerHeight } = this.styles
    const ci = this.ci
    const app = useAppStore()
    const settings = useSettingsStore()
    ci.height = height + (this.ci.textHeight - fontSize)
    this.header.width = ci.width = width
    ci.paddingX = paddingX
    ci.paddingY = paddingY
    ci.borderWidth = borderWidth
    ci.text = this.name?.substring(0, 20) || ''
    ci.textCenter = !!app.simpleView
    ci.textBold = !app.simpleView
    this.depth = this.isRoot || (this.parent.depth === 0 && this.isOnlyChild) ? 0 : this.parent?.depth + 1

    // collapse large pages when crawling
    this.collapsed = this.collapsed || (app.sitemap?.isBeingCrawled && this.children.length > 7 && !this.isRoot)

    if (settings.colorDisplay === 'Fill' && app.simpleView) {
      ci.borderColor = ci.fillColor = this.color
      ci.textColor = colorHelper.isLight(this.color) ? cssVar('--dark') : cssVar('--light')
    } else {
      this.header.fillColor = ci.borderColor = ci.textColor = this.color
      ci.fillColor = cssVar('--bg')
    }

    ci.draggable = !settings.lockDragging && !this.isRoot && !this.isLastPageLeftInSection && !ci.canvas.selection.size

    this.header.height = headerHeight
    this.header.fontSize = headerHeight / 2
    this.header.paddingY = headerHeight / 4
    this.header.paddingX = headerHeight / 2

    // calculate position only when necessary
    if (this.needsPositionCalc) {
      if (this.forceHorizontal || this.parent?.forceHorizontal) this.updateHorizontal()
      else this.updateVertical()
    }

    this.updateDraggedState()

    if (this.isBeingDraggedOver && ci.canvas.hasDraggedBlock && !this.blocks.length) {
      let block: SitemapBlock = ci.canvas.draggedItem.meta
      block.updateDropSpace(null, this)
    } else if (this.isBeingDraggedOver && !ci.canvas.hasDraggedBlock) {
      ci.fillColor = cssVar('--primary-lighter')
    }

    if (this.children && !this.collapsed) this.children.forEach(p => p.update())

    return this
  }

  get needsPositionCalc() {
    return this.isBeingDragged || this.sitemap.updatePagePositions
  }

  draw() {
    const simpleView = useAppStore().simpleView
    const ci = this.ci

    this.drawDraggedState()

    ci.draw()

    if (!simpleView) {
      this.header.draw()
    }

    this.drawChildren()

    if (this.collapsed) this.drawCollapsedState()

    if (!simpleView) {
      if (this.blocks) this.blocks.forEach(b => b.draw())
    }

    this.drawComments()

    this.updateCanvasMinMaxPoints()

    this.sitemap.pagesDrawn++
  }

  drawChildren() {
    if (this.children && !this.collapsed) this.children.forEach(p => {
      p.draw()
      if (!this.ci.isOutOfScreen() || !p.ci.isOutOfScreen()) {
        new Connection(this.ci, p.ci).draw()
      }
    })
  }

  updateCanvasMinMaxPoints() {
    const ci = this.ci
    const canvas = this.ci.canvas
    if (!canvas.draggedItem) {
      if (!canvas.minX || ci.left < canvas.minX) canvas.minX = ci.left - ci.borderWidth
      if (!canvas.maxX || ci.right > canvas.maxX) canvas.maxX = ci.right + ci.borderWidth
      if (!canvas.minY || ci.top < canvas.minY) canvas.minY = ci.top - ci.borderWidth
      if (!canvas.maxY || ci.bottom > canvas.maxY) canvas.maxY = ci.bottom + ci.borderWidth
    }
  }

  drawDraggedState() {
    if (this.dropSpaces.over && !this.isParentOf(this.ci.canvas.draggedItem.meta)) this.dropSpaces.over.draw()
    if (this.isRoot) return
    if (this.dropSpaces.before) this.dropSpaces.before.draw()
    if (this.dropSpaces.after) this.dropSpaces.after.draw()
  }

  isParentOf(page: SitemapPage) {
    return page?.parent === this
  }

  drawCollapsedState() {
    this.ci.drawBadge('left', 'bottom', countPages(this.children))
  }

  addSibling(location: 'before' | 'after' = 'after') {
    let idx = this.parent.children.indexOf(this)
    if (location === 'after') idx++
    return this.parent.addChildAt(idx)
  }

  addBlockAt(index: number, blockData = {}) {
    const block = new SitemapBlock(this, defaultBlock(blockData))
    new AddBlockCommand({ block, index }).execute()
    return block
  }

  addBlock(blockData = {}) {
    return this.addBlockAt(this.blocks.length, blockData)
  }

  addBlockAfter(block: SitemapBlock) {
    return this.addBlockAt(this.blocks.indexOf(block) + 1)
  }

  addBlockBefore(block: SitemapBlock) {
    return this.addBlockAt(this.blocks.indexOf(block))
  }

  toggleCollapse() {
    this.collapsed = !this.collapsed
    this.sitemap.updatePositions()
  }

  delete() {
    if (!this.parent) throw new Error('Root page can not be deleted')
    this.parent.removeChildPage(this)
  }
}

applyMixins(SitemapPage, [
  HasChildPagesMixin,
  DraggableMixin,
  HasIdMixin,
  HasCommentsMixin,
])

export interface SitemapPage extends HasChildPagesMixin {
}

export interface SitemapPage extends DraggableMixin {
}

export interface SitemapPage extends HasIdMixin {
}

export interface SitemapPage extends HasCommentsMixin {
}
