<script lang="ts" setup>
import ULoading from 'nvd-u/components/ULoading.vue'
import UModal from 'nvd-u/components/UModal.vue'
import CheckIcon from 'nvd-u/icons/CheckCircle.vue'
import CrownIcon from 'nvd-u/icons/CrownOutline.vue'
import { useAppStore } from 'src/stores/app.store'
import { useAuthStore } from 'src/stores/auth.store'
import { useUpgradeStore } from 'src/stores/upgrade.store'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const us = useUpgradeStore()
const auth = useAuthStore()
const app = useAppStore()

const emailVerified = computed(() => !!auth.user?.email_verified_at)

function ok() {
    router.push({ name: 'subscription' })
}

</script>

<template>
    <UModal
        class="upgrade-modal golden"
        v-model="us.modal"
        :title="!emailVerified ? 'Please Verify Your Email Address' : 'Please Upgrade Your Account'"
        ok-title="Upgrade"
        @ok="ok"
        :no-footer="!emailVerified"
    >
        <div class="upgrade-icon all-center mb-2">
            <CrownIcon/>
        </div>

        <div v-if="!emailVerified">
            <p>Looks like you have not verified your email yet.</p>

            <p>Verify your email now to enjoy a free PRO trial with unlimited features.</p>

            <p v-if="auth.emailVerificationReq.loading">
                <ULoading/>
            </p>
            <p class="d-flex align-items-center gap-2 text-primary"
               v-else-if="auth.emailVerificationReq.loaded">
                <CheckIcon/>
                Sent!
            </p>
            <p v-else>
                Haven't received the email? <a href="" @click.prevent="auth.emailVerificationReq.send()">Send again</a>
            </p>
        </div>

        <div v-else>
            <p v-if="us.reason === 'maxProjects'">
                The {{ auth.user.plan.title }} plan only allows a maximum of
                {{ auth.user.plan.max_projects }} projects. <span v-if="us.projectsRemaining < 0">You have exceeded this limit. Please upgrade your account or delete some projects.</span>
            </p>

            <p v-else-if="us.reason === 'maxPages'">
                The {{ auth.user.plan.title }} plan has a max limit of
                {{ app.maxPages }} pages per project. <span v-if="app.numPages > app.maxPages">You have exceeded this limit. Please upgrade your account or delete some pages.</span>
            </p>

            <p v-else-if="us.reason === 'maxMembers'">
                The {{ auth.user.plan.title }} plan has a max limit of
                {{ auth.user.plan.max_users + 1 }} member{{ auth.user.plan.max_users > 0 ? 's' : '' }} per account.
            </p>

            <p v-else-if="us.reason === 'maxInvites'">
                Please upgrade your account in order to send more invites.
            </p>

            <p>Would you like to upgrade your plan?</p>
        </div>
    </UModal>
</template>

<style lang="scss">
.upgrade-modal {
    .upgrade-icon {
        font-size: 7em;
        color: var(--golden);
    }
}
</style>
