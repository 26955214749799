import { dayjs } from 'nvd-js-helpers/dayjs'
import type { CanvasItem } from 'src/classes/canvas/CanvasItem'
import { Comment } from 'src/classes/Comment'
import { useAuthStore } from 'src/stores/auth.store'

export class HasCommentsMixin {
  newComment: Partial<Comment> = { text: '' }
  comments: Comment[] = []
  ci: CanvasItem

  addComment(activity: 'added' | 'resolved' = 'added') {
    let auth = useAuthStore()
    let data = {
      ...this.newComment,
      dateTime: dayjs().toISOString(),
      user: {
        id: auth.user?.id,
        name: auth.user?.name || 'You',
        avatar: auth.user?.avatar,
      },
    }
    // @ts-ignore
    let comment = new Comment(this, data)
    this.comments.push(comment)
    this.newComment.text = ''
    comment.save(activity)
  }

  drawComments() {
    let len = this.comments?.length
    if (!len) return
    let fill = this.mentionedComments?.length ? 'warning' : 'primary'
    this.ci.drawBadge('right', 'top', len, fill)
  }

  initComments() {
    this.newComment = { text: '' }
    this.comments = []
  }

  get unreadComments() {
    let auth = useAuthStore()
    return this.comments.filter(c => c.isReadBy(auth.user?.id))
  }

  get mentionedComments() {
    let auth = useAuthStore()
    return this.comments.filter(c => c.hasMentionOf(auth.user?.id) && !c.resolvedBy[auth.user?.id])
  }
}
