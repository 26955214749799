import type { SitemapBlock } from 'src/classes/SitemapBlock'
import type { SitemapPage } from 'src/classes/SitemapPage'
import { Command } from './Command'

interface Payload {
  block: SitemapBlock,
}

export class MoveBlockCommand extends Command {
  description = ''
  declare payload: Payload
  oldPage: SitemapPage
  oldIdx: number
  newPage: SitemapPage
  newIdx: number


  constructor(payload: Payload) {
    super(payload)
    this.payload = payload
  }

  label(): string {
    return `Move block`
  }

  run() {
    const { block } = this.payload

    this.oldPage = block.page
    this.oldIdx = block.index
    this.newPage = this.newPage || block.dropSpace.page
    this.newIdx = this.newIdx || block.dropSpace.index

    block.remove()
    block.addTo(this.newPage, this.newIdx)

    block.resetDropSpace()

    super.run()
  }

  undo() {
    const { block } = this.payload

    block.remove()
    block.addTo(this.oldPage, this.oldIdx)

    super.undo()
  }
}
