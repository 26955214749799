<script lang="ts" setup>
import { cssVar } from 'nvd-js-helpers/misc'
import UButton from 'nvd-u/components/UButton.vue'
import { useConfirm } from 'nvd-u/composables/Confirm'
import { useNotify } from 'nvd-u/composables/Notifiy'
import { CanvasDownloader } from 'src/classes/canvas/CanvasDownloader'
import { useTheme } from 'src/composables/useTheme'
import { env } from 'src/env'
import { useAppStore } from 'src/stores/app.store'
import { useAuthStore } from 'src/stores/auth.store'
import { useSitemapsStore } from 'src/views/projects/store'
import { ref } from 'vue'

const app = useAppStore()
const auth = useAuthStore()
const sitemaps = useSitemapsStore()
const loading = ref(false)

async function submit() {
    let yes = await useConfirm('Submit Template', `
    <p>Submitting this project as template will publish this to the world so anyone can access it.</p>
    <p>${env.appName} team will manually review your project, so it may take between a few hours to a few days before it appears in the list of templates.</p>
    <p>Are you sure?</p>
    `)

    if (!yes) return

    loading.value = true
    let body = { id: app.sitemap.id, light: '', dark: '' }

    if (auth.user?.is_admin) {
        let theme = useTheme()
        let oTheme = theme.value
        let d = new CanvasDownloader(app.sitemap)

        for (const themeName of ['light', 'dark']) {
            theme.setTheme(themeName)
            body[themeName] = await d.captureImage({ name: 'generated', bg: cssVar('--bg') })
        }
        theme.setTheme(oTheme)
    }

    await sitemaps.submitTemplateReq.send({
        body: JSON.stringify(body)
    })
    loading.value = false
    useNotify().success('Done!', 'Submitted successfully!')
}
</script>

<template>
    <UButton
        :loading="loading"
        class="submit-as-template-btn"
        compact
        @click="submit">
        Submit
    </UButton>
</template>

<style lang="scss" scoped>
</style>
