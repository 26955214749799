<script setup lang="ts">
import { _titleCase } from 'nvd-js-helpers/string-helper'
import { Url } from 'nvd-js-helpers/url-helper'
import AppIntro from 'src/components/AppIntro/AppIntro.vue'
import IsFlexGapSupported from 'src/components/common/IsFlexGapSupported.vue'
import MainHeader from 'src/components/layout/header/MainHeader.vue'
import Sidebar from 'src/components/layout/sidebar/Sidebar.vue'
import { useSocketIoAuth } from 'src/composables/useSocketIo'
import { env } from 'src/env'
import { useAppStore } from 'src/stores/app.store'
import { useAuthStore } from 'src/stores/auth.store'
import UpgradeModal from 'src/views/UpgradeModal.vue'
import { onBeforeMount, onMounted, watch } from 'vue'
import { RouterView, useRoute } from 'vue-router'
import LoginModal from './views/LoginModal.vue'

const route = useRoute()
const app = useAppStore()
const auth = useAuthStore()

watch(() => route.path, routeName => {
    let title = env.appName
    if (routeName) {
        title = `${_titleCase(routeName)} | ${title}`
    }
    document.querySelector('title').innerHTML = title
})

onBeforeMount(() => {
    if (auth.isLoggedIn) {
        auth.refreshUser()
        useSocketIoAuth()
    }
})

onMounted(() => {
    auth.form.source = Url.get('s') || auth.form.source
    auth.form.referer = document.referrer
})
</script>

<template>
    <AppIntro v-if="app.sitemap?.drawn && app.modals.intro" v-show="app.modals.intro"/>
    <div class="main-container d-flex flex-column">
        <MainHeader v-if="route.meta?.auth || route.name === 'sitemap'"/>

        <div class="d-flex flex-grow-1 content-container">
            <Sidebar v-if="route.meta?.auth"/>
            <main :class="route.name" class="flex-grow-1 main-content d-flex flex-column">
                <RouterView/>
            </main>
        </div>

        <LoginModal/>
        <IsFlexGapSupported/>
        <UpgradeModal/>
    </div>
</template>

<style lang="scss">

.main-container {
    height: 100vh;
    overflow: hidden;

    .content-container {
        overflow-y: auto;
        overflow-x: hidden;
    }

    .main-content {
        max-width: 1000px;

        &.login-as,
        &.templates,
        &.accept-invite,
        &.email-verified,
        &.sitemap, &.login, &.signup, &.forgot-password {
            max-width: 100%;
        }
    }
}

</style>
