import { defineStore } from 'pinia'
import type { SitemapBlock } from 'src/classes/SitemapBlock'
import type { SitemapPage } from 'src/classes/SitemapPage'
import type { SitemapSection } from 'src/classes/SitemapSection'
import { useAppStore } from 'src/stores/app.store'

type SearchItem = SitemapPage | SitemapBlock | SitemapSection

export const useSearchStore = defineStore('search', {
  state: () => ({
    open: false,
    query: '',
    focused: null as SearchItem | null,
  }),
  getters: {
    results() {
      const app = useAppStore()
      let q = this.query?.toLowerCase()
      let res = []

      if (q.length < 2) return res

      process(app.sitemap.tree[0])
      app.sitemap.sections.forEach(section => process(section))

      function process(item) {
        if (item?.name?.toLowerCase().includes(q)) res.push(item)

        if (item.blocks?.length && !app.simpleView) item.blocks.forEach(b => process(b))
        if (item.children?.length) item.children.forEach(ch => process(ch))
      }

      return res
    },
  },
  actions: {
    findItem(item: SearchItem) {
      if (!item) item = this.results[0]
      const app = useAppStore()
      app.sitemap.unCollapseParent(item)
      app.canvas.locateItem(item.ci)
      app.canvas.setSelectedItem(item.ci)
      this.open = false
    },
    onKeypress(e) {
      if (e.key !== 'ArrowUp' && e.key !== 'ArrowDown') return

      let maxIdx = this.results.length - 1

      if (!this.focused) this.focused = this.results[0]

      let currentIdx = this.results.indexOf(this.focused)

      let nextIdx = currentIdx < maxIdx ? currentIdx + 1 : 0
      if (e.key === 'ArrowUp') nextIdx = currentIdx > 0 ? currentIdx - 1 : maxIdx

      this.focused = this.results[nextIdx]
    },
  },
})
