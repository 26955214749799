import { applyMixins, cssFontSize, cssVar } from 'nvd-js-helpers/misc'
import { colorHelper } from 'nvd-u/helpers/color-helper'
import { DraggableMixin } from 'src/classes/Draggable.mixin'
import { HasIdMixin } from 'src/classes/HasId.mixin'
import type { Sitemap } from 'src/classes/Sitemap'
import type { Wireframe } from 'src/classes/Wireframe'
import { MoveBlockCommand } from 'src/commands/MoveBlockCommand'
import { sitemapConfig } from 'src/helpers/sitemap-helper'
import { Wireframes } from 'src/helpers/wireframes'
import { useSettingsStore } from 'src/stores/settings.store'
import { CanvasItem } from './canvas/CanvasItem'
import type { SitemapPage } from './SitemapPage'

export class SitemapBlock {
  page: SitemapPage
  sitemap: Sitemap
  _type = 'block'
  lastIdProp = 'lastBlockId'
  name: string = ''
  description: string = ''
  color: string = '#03a9f4'
  wireframe?: Wireframe
  ci: CanvasItem = null
  dropSpace?: SitemapBlock = null
  isDropSpace = false

  constructor(page: SitemapPage, data: Partial<SitemapBlock>) {
    this.page = page
    this.sitemap = page.sitemap
    this.setId(data)
    try {
      for (const key in data) {
        if (key === 'wireframe') {
          // @ts-ignore
          this.wireframe = Wireframes[data.wireframe]
        } else {
          this[key] = data[key]
        }
      }

      const fontSize = cssFontSize() * 0.75
      this.ci = new CanvasItem(this.page.sitemap.canvas, {
        top: 0,
        fontSize,
        paddingX: fontSize * 0.5,
        paddingY: fontSize * 0.5,
        height: 0,
        fillColor: this.color,
        text: this.name,
        textColor: cssVar('--light'),
        selectable: true,
        editable: true,
        hoverable: true,
        draggable: true,
        borderRadius: [2, 2, 2, 2],
        meta: this,
      })
    } catch (e) {
      console.error('Malformed block data.', e, data)
    }
  }

  toData() {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      color: this.color,
      wireframe: this.wireframe?.id,
    }
  }

  update() {
    const settings = useSettingsStore()
    const parent = this.page.ci
    this.ci.text = this.name
    this.ci.fillColor = this.color
    this.ci.textColor = colorHelper.isLight(this.color) ? cssVar('--dark') : cssVar('--light')

    this.ci.width = parent.width - parent.paddingX * 2
    this.ci.height = this.height
    this.ci.left = parent.left + parent.paddingX
    this.ci.top = this.top
    this.ci.draggable = !settings.lockDragging && this.ci.canvas.selection.size < 1

    if (this.ci.isDraggedItem) this.whileDragging()

    if (this.isBeingDraggedOver && this.ci.canvas.hasDraggedBlock) {
      this.whileBeingDraggedOver()
    }

    return this
  }

  whileDragging() {
    if (!this.dropSpace) {
      this.updateDropSpace(this)
    }
  }

  updateDropSpace(block?: SitemapBlock, page?: SitemapPage) {
    let dropSpace = this.dropSpace

    if (dropSpace) dropSpace.remove()

    if (!page) page = block.page

    if (!dropSpace || dropSpace.page !== page) {
      this.dropSpace = new SitemapBlock(page, {
        // @ts-ignore
        wireframe: this.wireframe?.id,
        name: this.name,
        color: 'rgba(0,0,0,0.05)',
        isDropSpace: true
      })
    }

    let idx = 0
    if (block) {
      idx = block.index
      if (this.ci.canvas.mouse.y > block.ci.relCy) idx++
    }

    this.dropSpace.addTo(page, idx)
  }

  whileBeingDraggedOver() {
    if (this.isDropSpace) return

    const draggedBlock = this.ci.canvas.draggedItem.meta
    draggedBlock.updateDropSpace(this)
  }

  onDragEnd() {
    if (!this.dropSpace) return
    if (this.previousBlock === this.dropSpace || this.nextBlock === this.dropSpace) {
      this.resetDropSpace()
      return
    }

    new MoveBlockCommand({ block: this }).execute()
  }

  draw() {
    this.ci.draw()
    if (!this.wireframe?.loaded) return
    const ctx = this.ci.canvas.ctx
    ctx.drawImage(this.wireframe.img, this.ci.left + this.ci.paddingX, this.ci.bottom - this.wireframe.height)
  }

  remove() {
    if (this.index > -1) this.page.blocks.splice(this.index, 1)
  }

  addTo(page: SitemapPage, atIndex: number) {
    this.page = page
    page.blocks.splice(atIndex, 0, this)
  }

  get top() {
    const parent = this.page.ci
    const { blockGap, headerHeight } = this.page.styles
    let firstBlockTop = parent.top + headerHeight + parent.paddingY + parent.textHeight

    let previous = this.previousBlock
    if (
      previous?.isBeingDragged // remove dragged block from the list while it is dragged
      || (this.isBeingDragged && this.dropSpace?.index < this.index && this.hasSameParentAs(this.dropSpace))
      // fix: when the dragged block goes up its original index, its top is not calculated correctly
    ) previous = previous.previousBlock

    if (!previous) return firstBlockTop

    return previous.ci.bottom + blockGap
  }

  get height() {
    const wireframeHeight = this.wireframe?.height || 0
    const textHeight = Math.max(
      sitemapConfig.block.height(),
      this.ci.textHeight + this.ci.paddingY * 2
    )
    const topPadding = (this.name || !wireframeHeight) ? textHeight : this.ci.paddingY
    return topPadding + wireframeHeight
  }

  get previousBlock() {
    return this.page.blocks[this.page.blocks.indexOf(this) - 1]
  }

  get nextBlock() {
    return this.page.blocks[this.page.blocks.indexOf(this) + 1]
  }

  get index() {
    return this.page.blocks.indexOf(this)
  }

  hasSameParentAs(block: SitemapBlock) {
    return this.page === block.page
  }

  resetDropSpace() {
    if (!this.dropSpace) return
    this.dropSpace.remove()
    this.dropSpace = null
  }
}

applyMixins(SitemapBlock, [
  DraggableMixin,
  HasIdMixin,
])

export interface SitemapBlock extends DraggableMixin {
}

export interface SitemapBlock extends HasIdMixin {
}
