<script lang="ts" setup>
import UButton from 'nvd-u/components/UButton.vue'
import UDropdown from 'nvd-u/components/UDropdown.vue'
import UMenuItem from 'nvd-u/components/UMenuItem.vue'
import CodeJsonIcon from 'nvd-u/icons/CodeJson.vue'
import DownloadIcon from 'nvd-u/icons/Download.vue'
import FilePdfBoxOutlineIcon from 'nvd-u/icons/FilePdfBoxOutline.vue'
import ImageOutlineIcon from 'nvd-u/icons/ImageOutline.vue'
import SitemapIcon from 'nvd-u/icons/Sitemap.vue'
import TextIcon from 'nvd-u/icons/Text.vue'
import { useAppStore } from 'src/stores/app.store'

const app = useAppStore()

</script>

<template>
    <UDropdown class="download-project-dd">
        <UButton icon transparent v-tooltip="'Export'">
            <DownloadIcon/>
        </UButton>

        <template #content>
            <div class="py-2 content" style="min-width: 12em;">
                <h4 class="text-center text-muted">Export Sitemap</h4>
                <UMenuItem @click="app.downloadPdf()">
                    <FilePdfBoxOutlineIcon/>
                    PDF
                </UMenuItem>
                <UMenuItem @click="app.downloadPng()">
                    <ImageOutlineIcon/>
                    PNG
                </UMenuItem>
                <UMenuItem @click="app.downloadSitemap('json')">
                    <CodeJsonIcon/>
                    JSON
                </UMenuItem>
                <UMenuItem @click="app.downloadSitemap('txt')">
                    <TextIcon/>
                    Text
                </UMenuItem>
                <UMenuItem @click="app.downloadSitemapXml()">
                    <SitemapIcon/>
                    Sitemap.xml
                </UMenuItem>
            </div>
        </template>
    </UDropdown>
</template>

