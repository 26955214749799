import { useAuthStore } from 'src/stores/auth.store'
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { devRoutes } from './dev-routes'

// @ts-ignore
const devModeRoutes = import.meta.env.DEV ? devRoutes : []

const router = createRouter({
  // @ts-ignore
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '',
      name: 'home',
      component: HomeView,
      meta: { auth: true },
    },
    {
      path: '/projects',
      name: 'projects',
      component: () => import('../views/projects/index.vue'),
      meta: { auth: true },
      children: [
        {
          path: 'create',
          name: 'projects.create',
          component: () => import('../views/projects/create.vue'),
        },
      ],
    },
    {
      path: '/profile',
      name: 'profile',
      meta: { auth: true },
      component: () => import('../views/profile/Profile.page.vue'),
      children: [
        {
          path: 'change-password',
          name: 'change-password',
          component: () => import('../views/profile/ChangePassword.page.vue'),
        },
        {
          path: 'delete-account',
          name: 'delete-account',
          component: () => import('../views/profile/DeleteAccount.page.vue'),
        },
      ]
    },
    {
      path: '/team',
      name: 'team',
      meta: { auth: true },
      component: () => import('../views/team/Team.page.vue')
    },
    {
      path: '/templates', name: 'templates', meta: { auth: true },
      component: () => import('../views/templates/Templates.page.vue')
    },
    {
      path: '/billing',
      name: 'billing',
      meta: { auth: true },
      component: () => import('../views/billing/Billing.page.vue'),
      children: [
        {
          path: 'subscription',
          name: 'subscription',
          component: () => import('../views/billing/Subscription.page.vue'),
        },
        {
          path: 'history',
          name: 'history',
          component: () => import('../views/billing/BillingHistory.page.vue'),
        },
        {
          path: 'payment-methods',
          name: 'paymentMethods',
          component: () => import('../views/billing/PaymentMethods.page.vue'),
        },
      ],
    },
    {
      path: '/p/:id?',
      name: 'sitemap',
      component: () => import('../views/SitemapView/SitemapEditor.vue')
    },
    {
      path: '/sitemap-finder',
      name: 'sitemapFinder',
      meta: { auth: true },
      component: () => import('@/views/SitemapFinder.page.vue')
    },
    { path: '/email-verified', name: 'email-verified', component: () => import('@/views/EmailVerifiedView.vue') },
    { path: '/accept-invite', name: 'accept-invite', component: () => import('@/views/AcceptInviteView.vue') },
    { path: '/login-as', name: 'login-as', component: () => import('@/views/LoginAs.page.vue') },
    {
      path: '',
      meta: { authRoute: true },
      children: [
        { path: '/forgot-password', name: 'forgot-password', component: () => import('../views/LoginView.vue') },
        { path: '/login', name: 'login', component: () => import('../views/LoginView.vue') },
        { path: '/signup', name: 'signup', component: () => import('../views/LoginView.vue') },
      ]
    },
    ...devModeRoutes,
  ]
})

router.beforeEach(async (to, from) => {
  const auth = useAuthStore()

  if (['projects.create', 'sitemap'].includes(<string>to.name) && auth.isLoggedIn) auth.refreshUser()

  if (to.meta.auth && !auth.isLoggedIn) return '/login'

  if (to.meta.authRoute && auth.isLoggedIn) return '/projects'
})

export default router
