<script setup lang="ts">
import UButton from 'nvd-u/components/UButton.vue'
import { useAppStore } from 'src/stores/app.store'
import { useIntroStore } from 'src/stores/intro.store'
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const app = useAppStore()
const intro = useIntroStore()
const router = useRouter()
const el = ref()
const transform = ref('scale(0)')
const route = useRoute()
const styles = computed(() => ({
    left: intro.modal.left + 'px',
    top: intro.modal.top + 'px',
    height: app.modals.intro ? 'auto' : 0,
    width: app.modals.intro ? `${intro.modal.width}px` : 0,
}))

function next() {
    if (intro.isLastStep) {
        close()
        return
    }

    intro.next()
}

function close() {
    intro.close()
    if (route.query.intro) router.replace('/projects/create')
}

onMounted(() => {
    intro.modal.el = el.value
    transform.value = 'scale(1)'
})
</script>

<template>
    <div class="app-intro-modal p-5 shadow-1" ref="el" :style="styles">
        <a href @click.prevent="close()" class="text-muted text-small font-italic close-intro-btn">
            {{ route.query.intro ? 'Done this before? Go to dashboard' : 'Close Tutorial' }}
        </a>

        <h3 class="d-flex align-items-center my-4">
            <span class="flex-grow-1">{{ intro.step.title }}</span>
            <span>{{ intro.step.idx + 1 }} / {{ intro.steps.length }}</span>
        </h3>

        <div class="my-4" v-html="intro.step.description"></div>

        <div class="mt-5 d-flex align-items-center justify-content-between">
            <UButton @click="intro.previous()" secondary :disabled="intro.isFirstStep">Previous</UButton>
            <UButton class="cta" @click="next()">
                {{ intro.isLastStep ? (route.query.intro ? 'CREATE MY SITEMAP' : 'Close') : 'Next' }}
            </UButton>
        </div>
    </div>
</template>

<style scoped lang="scss">
.app-intro-modal {
    position: absolute;
    background-color: var(--bg);
    border-radius: var(--border-radius);
    transition: all 0.3s ease-out;
    z-index: 999;
    line-height: 1.25;

    .close-intro-btn {
        position: absolute;
        right: 2rem;
        top: 1.5rem;
    }
}
</style>
