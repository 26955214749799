<script lang="ts" setup>
import { _sleep } from 'nvd-js-helpers/misc'
import UButton from 'nvd-u/components/UButton.vue'
import UDropdown from 'nvd-u/components/UDropdown.vue'
import UInput from 'nvd-u/components/UInput.vue'
import FindIcon from 'nvd-u/icons/Magnify.vue'
import CanvasItemIcon from 'src/components/common/CanvasItemIcon.vue'
import { useAppStore } from 'src/stores/app.store'
import { useSearchStore } from 'src/stores/search.store'
import { watch } from 'vue'

const app = useAppStore()
const search = useSearchStore()

watch(() => search.open, async open => {
    await _sleep(100)
    let input = document.querySelector('.search-dd .content input')
    if (open && input) {
        input.focus()
        input.select()
    }
})

watch(() => search.query, q => {
    search.focused = search.results[0]
})

</script>

<template>
    <UDropdown v-model="search.open" class="search-dd" :auto-close="false">
        <UButton icon transparent v-tooltip="'Search pages, sections or blocks'">
            <FindIcon/>
        </UButton>

        <template #content>
            <div class="p-2 content"
                 style="min-width: 14em;"
                 @keydown="search.onKeypress"
                 @keypress.enter="search.findItem(search.focused)"
                 @keyup.esc="search.open = false"
            >
                <UInput v-model="search.query" label="Search"/>
                <div class="items-list mt-2" v-if="search.results.length">
                    <div v-for="item in search.results"
                         :class="{focused: search.focused === item}"
                         class="p-3 rounded clickable text-truncate d-flex align-items-center gap-2"
                         @click="search.findItem(item)">
                        <CanvasItemIcon :item="item"/>
                        {{ item.name }}
                    </div>
                </div>
            </div>
        </template>
    </UDropdown>
</template>

<style lang="scss" scoped>
.search-dd {
    .focused {
        outline: 1px solid var(--golden);
        background-color: rgba(0, 0, 0, 0.05);
    }
}

</style>
