<script setup lang="ts">
import USwitch from 'nvd-u/components/USwitch.vue'
import MoonIcon from 'nvd-u/icons/WeatherNight.vue'
import SettingsItem from 'src/components/layout/header/project-settings/SettingsItem.vue'
import { useTheme } from 'src/composables/useTheme'
import { computed } from 'vue'

const theme = useTheme()
const darkMode = computed({
    get: () => theme.value === 'dark',
    set: v => theme.setTheme(v ? 'dark' : 'light')
})
</script>

<template>
    <SettingsItem>
        <MoonIcon/>
        <div class="flex-grow-1">Dark mode</div>
        <USwitch v-model="darkMode"/>
    </SettingsItem>
</template>

<style scoped lang="scss">

</style>
