import { Wireframe } from 'src/classes/Wireframe'

export const Wireframes = {
  menu: new Wireframe('menu', 18),
  cover: new Wireframe('cover', 40),
  cover_2: new Wireframe('cover_2', 38),
  products: new Wireframe('products', 28),
  carousel: new Wireframe('carousel', 45),
  slider: new Wireframe('slider', 21),
  title: new Wireframe('title', 18),
  title_2: new Wireframe('title_2', 18),
  section: new Wireframe('section', 37),
  article: new Wireframe('article', 21),
  article_2: new Wireframe('article_2', 21),
  article_3: new Wireframe('article_3', 21),
  article_4: new Wireframe('article_4', 21),
  content_1: new Wireframe('content_1', 20),
  content_2: new Wireframe('content_2', 20),
  content_3: new Wireframe('content_3', 20),
  content_4: new Wireframe('content_4', 20),
  cards: new Wireframe('cards', 49),
  cards_2: new Wireframe('cards_2', 65),
  footer: new Wireframe('footer', 41),
  footer_2: new Wireframe('footer_2', 18),
  footer_3: new Wireframe('footer_3', 16),
  links: new Wireframe('links', 18),
  buttons: new Wireframe('buttons', 18),
  button: new Wireframe('button', 18),
  portfolio: new Wireframe('portfolio', 55),
  gallery: new Wireframe('gallery', 56),
  chat: new Wireframe('chat', 72),
  form: new Wireframe('form', 52),
  subscribe: new Wireframe('subscribe', 18),
  sign_in: new Wireframe('sign_in', 35),
  contact_us: new Wireframe('contact_us', 48),
  pagination: new Wireframe('pagination', 14),
  timeline: new Wireframe('timeline', 18),
  FAQS: new Wireframe('FAQS', 42),
  map: new Wireframe('map', 46),
  map_2: new Wireframe('map_2', 60),
  pricing: new Wireframe('pricing', 53),
  table: new Wireframe('table', 44),
  breadcrumbs: new Wireframe('breadcrumbs', 18),
  breadcrumbs_2: new Wireframe('breadcrumbs_2', 18),
  tabs: new Wireframe('tabs', 44),
  features: new Wireframe('features', 24),
  features_2: new Wireframe('features_2', 26),
  video: new Wireframe('video', 40),
  team: new Wireframe('team', 30),
  product_card: new Wireframe('product_card', 26),
  profile: new Wireframe('profile', 20),
  quote: new Wireframe('quote', 20),
  rating: new Wireframe('rating', 18),
  search: new Wireframe('search', 18),
  dropdown: new Wireframe('dropdown', 18),
  audio: new Wireframe('audio', 18),
  bar_chart: new Wireframe('bar_chart', 28),
  pie_chart: new Wireframe('pie_chart', 28),
  line_chart: new Wireframe('line_chart', 28),
}
