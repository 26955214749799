import { defineStore } from 'pinia'
import type { Sitemap } from 'src/classes/Sitemap'
import type { SitemapNote } from 'src/classes/SitemapNote'
import type { SitemapPage } from 'src/classes/SitemapPage'
import type { SitemapSection } from 'src/classes/SitemapSection'
import { FetchRequest } from 'src/helpers/fetch-request'
import { useAppStore } from 'src/stores/app.store'

export type Version = {
  id: number,
  label: string,
  created_at: string,
  sitemap_id: number,
  user_id: number,
  user: { id: number, name: string },
  payload: {
    name: string,
    notes: Partial<SitemapNote>[],
    sections: Partial<SitemapSection>[],
    tree: Partial<SitemapPage>[],
  }
}

export const useVersionsStore = defineStore('versions', {
  state: () => ({
    req: new FetchRequest('', 'GET').withProps({
      pagination: true,
      paginationMode: 'append',
    }),
    previewReq: new FetchRequest('', 'GET'),
    expanded: {},
    selectedVersion: null as Version || null,
    originalSitemap: null as Sitemap || null,
    hasNewChanges: false,
  }),
  actions: {
    load() {
      const app = useAppStore()
      if (!app.sitemap.id || app.sitemap.isPublicDemo) return
      this.req.url = `sitemaps/${app.sitemap.id}/versions`
      this.req.send()
    },
    reset() {
      this.req.data = []
      this.expanded = {}
      this.selectedVersion = null
    },
    preview(version: Version) {
      this.previewReq.url = `/versions/${version.id}`
      this.selectedVersion = version

      // prevent unnecessary http request
      if (version.payload) {
        this.setPreview(version)
        return
      }
      // @ts-ignore
      this.previewReq.send().then(this.setPreview)
    },
    async setPreview({ payload }: Version) {
      this.selectedVersion.payload = payload
      const app = useAppStore()
      const Sitemap = (await import('src/classes/Sitemap')).Sitemap
      this.originalSitemap = this.originalSitemap || app.sitemap
      app.setSitemap(new Sitemap(app.canvas, {
        ...app.sitemap.toData(),
        id: app.sitemap.id,
        owner_id: app.sitemap.owner_id,
        members: app.sitemap.members,
        ...payload,
      }))
    },
    closePreview() {
      const app = useAppStore()
      app.setSitemap(this.originalSitemap)
      this.selectedVersion = null
      this.originalSitemap = null
    },
  },
})
