<script setup lang="ts">
import IntroArrow from 'src/components/AppIntro/IntroArrow.vue'
import IntroModal from 'src/components/AppIntro/IntroModal.vue'
import { useIntroStore } from 'src/stores/intro.store'
import { onMounted, watch } from 'vue'

const intro = useIntroStore()

watch(() => intro.step, intro.update)
onMounted(intro.update)
</script>

<template>
    <div class="app-intro">
        <IntroModal/>
        <IntroArrow v-show="intro.step?.direction !== 'center'"/>
        <div class="overlay"></div>
    </div>
</template>

<style scoped lang="scss">
.app-intro {
    overflow: hidden;

    .overlay {
        width: 100vw;
        height: 100vh;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 99;
        background-color: rgba(33, 33, 33, 0.7);
    }
}
</style>
