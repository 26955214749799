<script lang="ts" setup>
import UButton from 'nvd-u/components/UButton.vue'
import UInput from 'nvd-u/components/UInput.vue'
import UModal from 'nvd-u/components/UModal.vue'
import USelect from 'nvd-u/components/USelect.vue'
import AccountMultipleOutlineIcon from 'nvd-u/icons/AccountMultipleOutline.vue'
import LinkVariantIcon from 'nvd-u/icons/LinkVariant.vue'
import ApmEditable from 'src/components/common/ApmEditable.vue'
import SitemapUser from 'src/components/common/SitemapUser.vue'
import { useAppStore } from 'src/stores/app.store'
import { useAuthStore } from 'src/stores/auth.store'
import { useShareStore } from 'src/stores/share.store'
import { useUpgradeStore } from 'src/stores/upgrade.store'
import { emailRule } from 'src/Vee/rules/email.rule'
import { requiredRule } from 'src/Vee/rules/required.rule'
import { useValidator } from 'src/Vee/useValidator'
import { Validator } from 'src/Vee/validator'

let app = useAppStore()
let share = useShareStore()
let auth = useAuthStore()
let us = useUpgradeStore()

let v = useValidator(share.inviteParams, (v: Validator) => {
    v.addRule(requiredRule('email'))
    v.addRule(emailRule('email'))
})

function invite() {
    v.validate()
    if (v.hasErrors) return

    share.invite().then(i => {
        v.reset()
    }).catch(e => {
        if (share.inviteUserReq.error.includes('upgrade your account')) {
            us.showModal('maxInvites')
        }
    })
}

</script>

<template>
    <UButton class="project-share-btn" icon transparent v-tooltip="'Share'"
             @click="e => share.modals.share = !share.modals.share">
        <AccountMultipleOutlineIcon/>
    </UButton>
    <UModal
        class="share-modal"
        v-model="share.modals.share"
        :title="`Share '${app.sitemap.name}'`"
        cancel-only
        cancel-title="Close"
    >
        <div class="mb-5">
            <h4>Invite people</h4>
            <UInput
                v-model="share.inviteParams.email"
                class="mb-4"
                type="email"
                label="Email"
                :errors="v.errors.email"
                @keypress.enter="invite"
            />
            <div class="d-flex align-items-center gap-2" v-if="share.inviteParams.email && !v.errors?.email?.length">
                <USelect
                    class="flex-grow-1"
                    label="Role"
                    v-model="share.inviteParams.role"
                    :options="['Editor', 'Commenter', 'Viewer']"
                />
                <UButton
                    @click="invite"
                    :loading="share.inviteUserReq.loading"
                >Invite
                </UButton>
            </div>
        </div>
        <div class="mb-5">
            <h4>People with access</h4>
            <SitemapUser :user="app.sitemap.owner" role="Owner" class="mb-2" type="member"/>
            <SitemapUser
                v-for="member in app.sitemap.members"
                :key="member.id"
                :user="member"
                :role="member.pivot.role"
                type="member"
                class="mb-2"
            />
            <SitemapUser
                v-for="invite in app.sitemap.pending_invites"
                :key="invite.id"
                :user="invite"
                :role="invite.role"
                type="invite"
                class="mb-2"
            />
        </div>
        <div class="mb-4 public-access">
            <h4>Public access</h4>
            <div class="d-flex align-items-center gap-2 mb-2">
                <div class="flex-grow-1">Allow people with link to view the project</div>
                <ApmEditable
                    v-model="app.sitemap.is_public"
                    :url="`sitemaps/${app.sitemap.id}`"
                    filed-name="is_public"
                    @update:modelValue="e => app.updateSitemapInListing()"
                    type="switch"
                />
            </div>
            <UButton compact v-if="app.sitemap.is_public" @click="share.copyLink()">
                <LinkVariantIcon/>
                Copy link
            </UButton>
        </div>
    </UModal>
</template>

<style scoped lang="scss">
</style>
