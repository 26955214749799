import { cssFontSize } from 'nvd-js-helpers/misc'
import { useStorage } from 'nvd-use-storage'
import type { SitemapPage } from 'src/classes/SitemapPage'
import { useAppStore } from 'src/stores/app.store'

const fontSize = () => cssFontSize() * 0.75
const paddingY = () => useAppStore().simpleView ? fontSize() : fontSize() * 0.5
export const sitemapConfig = {
  root: {
    top: 70, // root page top
    left: 80
  },
  page: {
    fontSize: fontSize,
    paddingY: paddingY,
    gap: 20, // gap bw tw pages
    borderWidth: 2,
    leftGap: 40,
  },
  block: {
    height: () => Math.round(fontSize() + paddingY() * 2),
    gap: 3, // gap bw two blocks
  },
  connection: {
    offsetX: 20
  }
}

export const defaultBlock = (data = {}) => {
  let color = useStorage('last-block-color', '#03a9f4')
  return {
    name: 'Block name',
    color: color.value,
    ...data
  }
}

export const defaultPage = (data = {}) => {
  let color = useStorage('last-page-color', '#00b3a1')
  return {
    name: 'Page name',
    color: color.value,
    link: '',
    blocks: [],
    ...data,
  }
}

export const newSitemapTemplate = (data: any = {}) => ({
  name: data.name || 'Untitled Sitemap',
  tree: data.tree || [
    defaultPage({
      name: 'Home',
      blocks: [
        defaultBlock({ name: '', wireframe: 'menu' }),
        defaultBlock({ name: '', wireframe: 'cover_2' }),
        defaultBlock({ name: 'Testimonials', wireframe: 'team' }),
        defaultBlock({ name: 'Features', wireframe: 'features' }),
        defaultBlock({ name: 'FAQs', wireframe: 'FAQS' }),
        defaultBlock({ name: '', wireframe: 'footer_2' }),
      ],
      children: [
        defaultPage({ name: 'Page 1', color: '#03a9f4' }),
        defaultPage({ name: 'Page 2' }),
        defaultPage({ name: 'Page 3', color: '#03a9f4' }),
        defaultPage({ name: 'Page 4' }),
      ],
    }),
  ],
  sections: data.sections || [
    {
      name: 'Section: Links',
      children: [
        defaultPage({ name: 'Privacy Policy', blocks: [defaultBlock({ name: '', wireframe: 'content_1' })] }),
        defaultPage({ name: 'Terms of Service', blocks: [defaultBlock({ name: '', wireframe: 'content_1' })] }),
        defaultPage({ name: '404', blocks: [defaultBlock({ name: '', wireframe: 'title' })] }),
      ]
    }
  ],
})

export function countPages(pages: SitemapPage[]) {
  return pages.reduce((total, p) => 1 + countPages(p.children) + total, 0)
}
