<script lang="ts" setup>
import { sendEmailToAdmin } from '@/helpers/misc'
import { useAuthStore } from '@/stores/auth.store'
import { Storage } from 'nvd-js-helpers/storage-helper'
import { onMounted, ref } from 'vue'

const isFlexGapSupported = ref(true)
const auth = useAuthStore()

onMounted(() => {
    let docElement = document.documentElement
    // create flex container with row-gap set
    let flex = document.createElement('div')
    flex.style.display = 'flex'
    flex.style.flexDirection = 'column'
    flex.style.rowGap = '1px'

    // create two elements inside it
    flex.appendChild(document.createElement('div'))
    flex.appendChild(document.createElement('div'))

    // append to the DOM (needed to obtain scrollHeight)
    docElement.appendChild(flex)
    isFlexGapSupported.value = flex.scrollHeight === 1
    flex.parentNode && flex.parentNode.removeChild(flex)

    // notify admin if not supported
    if (!isFlexGapSupported.value && !Storage.get('is-flex-gap-supported')) {
        Storage.set('is-flex-gap-supported', 'not-supported')
        sendEmailToAdmin('Flex Gap not supported', { user: auth.user?.email })
    }
})

function close() {
    isFlexGapSupported.value = false
}
</script>

<template>
    <div class="not-supported bg-danger p-4 d-flex" v-if="!isFlexGapSupported">
        <a href="" @click.prevent="close" class="mr-4 text-light">&times;</a>
        <div class="flex-grow-1">Looks like you are using an old browser. For best experience, please
            consider upgrading
            your browser.
        </div>
    </div>
</template>

<style scoped lang="scss">
</style>
