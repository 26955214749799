import { env } from 'src/env'

export interface IntroStep {
  idx: number,
  title: string,
  description: string,
  target: string,
  highlightedElements?: string,
  direction: 'ul' | 'ur' | 'dl' | 'dr' | 'center' | 'l' | 'r' | 'd' | 'u',
  beforeSelect?: Function,
  beforeDeSelect?: Function,
}

export const introSteps: IntroStep[] = [
  {
    idx: 0,
    title: `Welcome to ${env.appName}`,
    description: `<p>This is a quick demo to show you around.</p>
<p>It will only take a minute!</p>`,
    target: '.sitemap-editor',
    direction: 'center',
  },
  {
    idx: 0,
    title: `The Canvas`,
    description: `<p>This is your canvas to build your sitemap.</p>
<p>Hover over items, click on them or just drag and drop to perform different actions.</p>`,
    target: '.sitemap-editor',
    direction: 'l',
    beforeSelect() {
      let target = document.querySelector('.sitemap-editor')
      // @ts-ignore
      this._bg = target.style.backgroundColor
      // @ts-ignore
      target.style.backgroundColor = 'var(--bg)'
    },
    beforeDeSelect() {
      // @ts-ignore
      document.querySelector('.sitemap-editor').style.backgroundColor = this._bg
    },
  },
//   {
//     idx: 0,
//     title: `Project History`,
//     description: `<p>Click this button to view and restore previous versions of your project.</p>
// <p>It's easy to make mistakes, but easier to fix them!</p>`,
//     target: '.project-history-btn',
//     highlightedElements: '.sitemap-sidebar',
//     direction: 'ur',
//     beforeSelect() {
//       let app = useAppStore()
//       app.modals.history = true
//     },
//     beforeDeSelect() {
//       let app = useAppStore()
//       app.modals.history = false
//     },
//   },
  // {
  //   idx: 0,
  //   title: `Export Project`,
  //   description: `<p>Click this button to export your project in PDF, PNG, sitemap.xml and more!</p>`,
  //   target: '.download-project-dd .u-btn',
  //   direction: 'ur',
  // },
  {
    idx: 0,
    title: `Dynamic Sidebar`,
    description: `<p>This sidebar shows you different view types. Switch between view types depending on your project stage.</p>
        <p>The sidebar also shows you various properties of the item you have selected in canvas.</p>`,
    target: '.sitemap-sidebar',
    direction: 'ur',
  },
//   {
//     idx: 2,
//     title: 'Content View',
//     description: `<p>Content View gets you access to content writing tools and SEO related stuff.</p>
// <p>Use the formatting tools or just write markdown to format your content.</p>`,
//     target: '#content-view-toggle-section .u-switch',
//     highlightedElements: '.page-content-model .apm-modal',
//     direction: 'u',
//     beforeSelect() {
//       let app = useAppStore()
//       app.contentModalPage = app.sitemap.tree[0].children[0] || app.sitemap.tree[0]
//     },
//     beforeDeSelect() {
//       let app = useAppStore()
//       app.contentModalPage = null
//     },
//   },
  // {
  //   idx: 4,
  //   title: 'Minimap',
  //   description: `Click on this minimap to move around the canvas easily and to have a birds-eye view of large projects.`,
  //   target: '.apm-minimap',
  //   direction: 'dl',
  //   beforeSelect() {
  //     let app = useAppStore()
  //     app.canvas.updateOrigin(0, -400)
  //   },
  //   beforeDeSelect() {
  //     let app = useAppStore()
  //     app.canvas.updateOrigin(0, 0)
  //   },
  // },
  // {
  //   idx: 4,
  //   title: 'Realtime Collaboration: Sharing',
  //   description: `Click this button to share your project with anyone.`,
  //   target: '.project-share-btn',
  //   direction: 'u',
  //   highlightedElements: '.share-modal .apm-modal',
  //   beforeSelect() {
  //     const share = useShareStore()
  //     share.modals.share = true
  //   },
  //   beforeDeSelect() {
  //     const share = useShareStore()
  //     share.modals.share = false
  //   },
  // },
  // {
  //   idx: 4,
  //   title: 'Realtime Collaboration: Live Comments',
  //   description: `You can comment on each page to make the collaboration easier.`,
  //   target: '.page-comments-btn',
  //   highlightedElements: '.sitemap-editor',
  //   direction: 'l',
  //   beforeSelect() {
  //     let app = useAppStore()
  //     app.canvas.persistHoveredState = true
  //     app.canvas.updateOrigin(-app.sitemap.tree[0].ci.left + 120, 0)
  //     app.canvas.setHoveredItem(app.sitemap.tree[0].ci)
  //     app.commentsModalPage = app.sitemap.tree[0]
  //   },
  //   beforeDeSelect() {
  //     let app = useAppStore()
  //     app.canvas.updateOrigin(0, 0)
  //     app.canvas.persistHoveredState = false
  //     app.canvas.setHoveredItem(null)
  //     app.commentsModalPage = null
  //   },
  // // },
  // {
  //   idx: 4,
  //   title: 'Realtime Collaboration: Active Users',
  //   description: `People working on this project right now are listed here. Anything anyone does in the project is updated across the team in realtime.`,
  //   target: '.active-users',
  //   direction: 'ul',
  // },
  {
    idx: 1,
    title: 'Project Tools',
    description: `<p>Use this menu to:
<ul>
<li>Share your project with others and invite them for collaboration.</li>
<li>Export your project in XML, PDF, PNG, JSON and more.</li>
<li>View version history of the project.</li>
<li>Update settings and more!</li>
</ul>
</p>`,
    target: '.project-tools',
    direction: 'ur',
  },
  {
    idx: 1,
    title: 'Account Dashboard',
    description: `<p>Click here to go back to your dashboard at any time.</p>
    <p>Thank you for completing the demo.</p>
    <p>Ready?</p>
`,
    target: '.home-link',
    direction: 'ul',
  },
]
