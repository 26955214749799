import { useConfirm } from 'nvd-u/composables/Confirm'
import { useNotify } from 'nvd-u/composables/Notifiy'
import { defineStore } from 'pinia'
import type { Command } from 'src/commands/Command'
import { FetchRequest } from 'src/helpers/fetch-request'
import { useAppStore } from 'src/stores/app.store'
import { useAuthStore } from 'src/stores/auth.store'
import { useVersionsStore } from 'src/stores/versions.store'

export const useCommandsStore = defineStore('commands', {
  state: () => ({
    history: [],
    currentCommandIdx: -1,
    req: new FetchRequest('', 'POST').withProps({ showErrorToast: false }),
    unsavedProject: false,
  }),
  getters: {
    currentCommand(): Command {
      return this.history[this.currentCommandIdx]
    },
    nextCommand(): Command {
      return this.history[this.currentCommandIdx + 1]
    },
    canUndo(): boolean {
      return this.currentCommandIdx >= 0
    },
    canRedo(): boolean {
      return this.history.length > 0 && this.currentCommandIdx < this.history.length - 1
    },
  },
  actions: {
    undo() {
      if (this.canUndo) this.currentCommand.undo()
    },
    redo() {
      if (this.canRedo) {
        if (!this.nextCommand) console.log('Error: cant redo')
        this.nextCommand.redo()
      }
    },
    save(undo = false) {
      let app = useAppStore()
      const auth = useAuthStore()
      if (!auth.isLoggedIn) {
        this.unsavedProject = true
        return
      }
      if (!app.sitemap?.id || app.sitemap?.is_template) return

      this.unsavedProject = false
      this.req.url = `sitemaps/${app.sitemap.id}/commands/`
      this.req.url += undo ? 'undo' : 'save'
      let command = undo ? this.nextCommand : this.currentCommand
      let version = {
        label: command.label(),
        payload: app.sitemap.toData()
      }
      this.req.send({
        body: JSON.stringify(version)
        // @ts-ignore
      }).then((res: { id: number, activities: number }) => {
        app.sitemap.updated_at = new Date().toISOString()
        const versions = useVersionsStore()
        if (versions.req.data?.data?.length) {
          if (undo) {
            versions.req.data.data.shift()
            return
          }
          versions.req.data.data.unshift({
            label: version.label,
            // @ts-ignore
            id: parseInt(res.id),
            created_at: app.sitemap.updated_at,
            sitemap_id: app.sitemap.id,
            user: { id: auth.user.id, name: auth.user.name }
          })
        }
        if (res.activities && !auth.user.email.includes('08es34+cy')) {
          auth.user = { ...auth.user, activities_count: res.activities }
        }
      }).catch(r => {
        if (this.req.error === 'Can not nest more child pages') {
          let message = '<p>There is a limit on the maximum number of child pages you can nest.</p>'
          message += '<p class="p-3 rounded bg-warning text-center font-weight-bold">Your progress may not be saved if you keep seeing this message.</p>'
          message += '<p>Refreshing your browser or deleting some of the nested pages usually resolves this issue.</p>'
          message += '<p>Please feel free to contact us if you think this should not happen.</p>'
          return useConfirm('Oops! Can not nest more pages', message, {
            okTitle: 'I understand',
            cancelTitle: 'Close'
          })
        } else {
          useNotify().error('Oops! Could not save your work', this.req.error)
        }
      })
    },
    saveUndo() {
      return this.save(true)
    },
    reset() {
      this.unsavedProject = false
      this.history = []
      this.currentCommandIdx = -1
    },
  },
})
