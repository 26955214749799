import { defineStore } from 'pinia'
import { useAppStore } from 'src/stores/app.store'
import { useAuthStore } from 'src/stores/auth.store'
import { useTeamStore } from 'src/stores/team.store'
import { useSitemapsStore } from 'src/views/projects/store'
import { computed, ref } from 'vue'

export type UpgradeReason = 'maxProjects' | 'maxPages' | 'maxMembers' | 'maxInvites'

export const useUpgradeStore = defineStore('upgrade', () => {
  const modal = ref(false)
  const reason = ref<UpgradeReason>('maxPages')

  const projectsRemaining = computed(() => {
    let auth = useAuthStore()
    let sitemaps = useSitemapsStore()
    let maxProjects = auth.isOnTrial || auth.belongsToATeam ? 1000 : auth.user.plan.max_projects
    return maxProjects - sitemaps.ownProjectsCount
  })

  function showModal(r: UpgradeReason) {
    const auth = useAuthStore()
    if (auth.user?.import_in_progress) return
    reason.value = r
    modal.value = true
  }

  function checkMaxProjects() {
    if (projectsRemaining.value > 0) return true
    showModal('maxProjects')
    return false
  }

  function checkMaxProjectsExceeded() {
    if (projectsRemaining.value >= 0) return true
    showModal('maxProjects')
    return false
  }

  function checkMaxPages() {
    let app = useAppStore()
    if (app.sitemap.isPublicDemo) return true

    if (app.numPages < app.maxPages) return true
    showModal('maxPages')
    return false
  }

  function checkMaxPagesExceeded() {
    let app = useAppStore()
    if (app.sitemap.isPublicDemo) return true

    if (app.numPages <= app.maxPages) return true
    showModal('maxPages')
    return false
  }

  function checkMaxMembers() {
    let auth = useAuthStore()
    let team = useTeamStore()

    let numMembers = team.req.data.filter(m => m.is_member).length
    if (numMembers < auth.maxTeamMembers) return true

    showModal('maxMembers')
    return false
  }

  return {
    modal,
    reason,
    projectsRemaining,
    showModal,
    checkMaxProjects,
    checkMaxPages,
    checkMaxMembers,
    checkMaxProjectsExceeded,
    checkMaxPagesExceeded,
  }
})
